import React from "react";
import Modal from "react-modal";
import { useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import { useRef } from "react";
import { PlusSquareFill } from "react-bootstrap-icons";

function AddEquipmentToParticipantModal({isOpen, onRequestClose, onSubmit, input, equipmentTypeText, equipmentTypeList, participantName}) {
    const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 600)

    const [equipment, setEquipment] = React.useState([]);
    const [selectedEquipment, setSelectedEquipment] = React.useState(null);

    const axiosPrivate = useAxiosPrivate();
    const typeaheadEquipmentRef = useRef(null);

    useEffect(() => {
		function handleResize() {
			setIsMobile(window.innerWidth <= 600)
		}
		window.addEventListener("resize", handleResize);
		handleResize();
        loadEquipment();
	}, [])

    async function loadEquipment() {
        await axiosPrivate.get("/equipment")
        .then((response) => {
            setEquipment(response.data)
        })
        .catch(() => {
            setEquipment([])
        })
    }

    function addEquip(e) {
        e.preventDefault();
        if(selectedEquipment != null) {
            onSubmit(selectedEquipment.eid)
        }
    }

    return (
        <Modal
			isOpen={isOpen}
			onRequestClose={onRequestClose}
			contentLabel={"Füge " + participantName + " " + equipmentTypeText + " hinzu:"}
            style={{content: {
                left: !isMobile ? "25%": "0%",
                right: !isMobile ? "25%": "0%",
                border: 0,
                background: "none"
            }}}
            ariaHideApp={false}
		>

            <div className="modal-dialog" role="document">
                <div className="modal-content rounded-4 shadow text-bg-light border border-black">
                <div className="modal-header p-5 pb-4 border-bottom-0">
                    <div className="row">
                        <h1 className="col-10 fw-bold mb-0 fs-2">{"Füge " + participantName + " " + equipmentTypeText + " hinzu:"}</h1>
                        <div className="col-2">
                            <div className="row"> 
                                <button type="button" className="col btn me-3 btn-lg rounded-3 btn-danger" style={{"--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem"}} onClick={() => {
                                    setSelectedEquipment();
                                }} >CLEAR</button>
                                <button type="button" className="col btn-close my-auto" onClick={onRequestClose} aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-body p-5 pt-0">
                    <div className="row">
                        <div className="col-10">
                            <Typeahead 
                                id="typeahead-equipment-participant"
                                labelKey={option => `${option.equipmentType} - ${option.size} - eid: ${option.eid}`}
                                options={equipment.filter(e => equipmentTypeList.includes(e.equipmentType)).filter(e => e.participant === null)}
                                onChange={(selected) => {setSelectedEquipment(selected[0])}}
                                ref={typeaheadEquipmentRef}
                                placeholder="Ausrüstung suchen"
                            />
                        </div>
                        <div className="col-2 align-self-center">
                            <PlusSquareFill size={30} color="green" onClick={e => addEquip(e)} />
                        </div>
                    </div>  
                </div>
                </div>
            </div>

        </Modal>
    )

}
export default AddEquipmentToParticipantModal;