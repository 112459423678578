import React from "react";
import {Modal, Form, InputGroup, Row, Col, Container, Button} from "react-bootstrap";

function NewParticipantModal({show, onSubmit, onHide}) {
    const [inputs, setInputs] = React.useState({"localGroup": "Bezirk_Esslingen", "drsa": "NOTHING", "imageRight": "NO_IMAGES_ALLOWED"});
    const [validated, setValidated] = React.useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if(!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            onSubmit(inputs);
            setValidated(true);
            setInputs({"localGroup": "Bezirk_Esslingen", "drsa": "NOTHING", "imageRight": "NO_IMAGES_ALLOWED"})
        } 

        setValidated(true)
    }

    return(
        <Modal show={show} onHide={onHide} backdrop="static" size="lg">
            <Modal.Header closeButton className="bg-primary text-secondary border-primary">
                <Modal.Title>
                    Füge neuen Teilnehmer hinzu
                </Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                
                    <Container>
                        <Row>
                            <InputGroup as={Col}>
                                <InputGroup.Text id="labelTextFirst">Vorname</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Max"
                                    name="first"
                                    value={inputs.first || ""}
                                    id="first_input"
                                    aria-label="Vorname"
                                    aria-describedby="labeltextFirst"
                                    onChange={handleChange}
                                    required/>
                            </InputGroup>
                            <InputGroup as={Col}>
                                <InputGroup.Text id="labelTextLast">Nachname</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder="Musterman"
                                    name="last"
                                    value={inputs.last || ""}
                                    id="last_input"
                                    aria-label="Nachname"
                                    aria-describedby="labeltextLast"
                                    onChange={handleChange}
                                    required/>
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text>Gliederung</InputGroup.Text>
                                <Form.Select
                                    aria-label="Wähle Gliederung"
                                    name="localGroup"
                                    id="localgroup_input"
                                    value={inputs.localGroup}
                                    onChange={handleChange}
                                    required>
                                    <option value="Bezirk_Esslingen">Bezirk Esslingen</option>
                                    <option value="Aichtal">OG Aichtal</option>
                                    <option value="Altbach">OG Altbach</option>
                                    <option value="Berkheim">OG Berkheim</option>
                                    <option value="Bernhausen">OG Bernhausen</option>
                                    <option value="Esslingen">OG Esslingen</option>
                                    <option value="Kirchheim">OG Kirchheim</option>
                                    <option value="Neuffen_Beuren">OG Neuffen-Beuren</option>
                                    <option value="Nuertingen">OG Nürtingen</option>
                                    <option value="Ostfildern_Denkendorf">OG Ostfildern-Denkendorf</option>
                                    <option value="Weilheim">OG Weilheim</option>
                                    <option value="Wendlingen">OG Wendlingen</option>
                                    <option value="Wernau">OG Wernau</option>
                                </Form.Select>
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text>Geburtsdatum</InputGroup.Text>
                                <Form.Control
                                    type="date"
                                    name="dateOfBirth"
                                    value={inputs.dateOfBirth || ""}
                                    id="dateofbirth_input"
                                    onChange={handleChange}
                                    required />
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup as={Col}>
                                <InputGroup.Text>Telefonnummer</InputGroup.Text>
                                <Form.Control
                                    type="tel"
                                    name="phoneNumber"
                                    value={inputs.phoneNumber || ""}
                                    id="phonenumber_input"
                                    onChange={handleChange}
                                    required/>
                            </InputGroup>
                            <InputGroup as={Col}>
                                <InputGroup.Text>Threema-ID</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="threemaID"
                                    value={inputs.threemaID || ""}
                                    id="threemaid_input"
                                    onChange={handleChange}
                                    required />
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text>Emailadresse</InputGroup.Text>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    value={inputs.email || ""}
                                    id="email_input"
                                    onChange={handleChange}
                                    required />
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text>Notfallnummer</InputGroup.Text>
                                <Form.Control
                                    type="tel"
                                    name="emergency_phoneNumber"
                                    value={inputs.emergency_phoneNumber || ""}
                                    id="emergency_phoneNumber_input"
                                    onChange={handleChange}
                                    required />
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup as={Col}>
                                <InputGroup.Text>DRSA</InputGroup.Text>
                                <Form.Select
                                    aria-label="Wähle Abzeichen"
                                    name="drsa"
                                    id="drsa_input"
                                    onChange={handleChange}
                                    required >
                                    <option value="NOTHING">Kein DRSA</option>
                                    <option value="JUNIORRETTER">Juniorretter</option>
                                    <option value="BRONZE">DRSA Bronze</option>
                                    <option value="SILBER">DRSA Silber</option>
                                    <option value="GOLD">DRSA Gold</option>
                                </Form.Select>
                            </InputGroup>
                            <InputGroup as={Col}>
                                <InputGroup.Text>Bildrechte</InputGroup.Text>
                                <Form.Select
                                    aria-label="Wähle die Bildrechte"
                                    name="imageRight"
                                    id="imageRight_input"
                                    onChange={handleChange}
                                    required>
                                    <option value="NO_IMAGES_ALLOWED">keine Bilder</option>
                                    <option value="ONLY_IMAGES_FOR_INTERN_USE_ALLOWED">Bilder nur intern</option>
                                    <option value="ALL_IMAGES_ALLOWED">Bilder öffentlich</option>
                                </Form.Select>
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text>Anmerkung</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="note"
                                    value={inputs.note || ""}
                                    id="note_input"
                                    onChange={handleChange}/>
                            </InputGroup>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-primary">
                    <Button type="submit" variant="secondary">Abschließen</Button>
                    <Button variant="primary" onClick={() => setInputs({"localGroup": "Bezirk_Esslingen", "drsa": "NOTHING", "imageRight": "NO_IMAGES_ALLOWED"})}>Felder leeren</Button>
                </Modal.Footer>
            </Form>

            {/*
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="Füge neuen Teilnehmer hinzu"
                style={{content: {
                    left: !isMobile ? "25%": "0%",
                    right: !isMobile ? "25%": "0%",
                    border: 0,
                    background: "none"
                }}}
                ariaHideApp={false}
            >
                
                <div className="modal-dialog" role="document">
                    <div className="modal-content rounded-4 shadow text-bg-light border border-black">
                    <div className="modal-header p-5 pb-4 border-bottom-0">
                        <h1 className="fw-bold mb-0 fs-2">Füge neuen Teilnehmer hinzu</h1>
                        <div className="row"> 
                            <button type="button" className="col btn me-3 btn-lg rounded-3 btn-danger" style={{"--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem"}} onClick={() => {
                                setInputs({"localGroup": "Bezirk_Esslingen", "drsa": "NOTHING", "imageRight": "NO_IMAGES_ALLOWED"});
                            }} >CLEAR</button>
                            <button type="button" className="col btn-close my-auto" onClick={props.onRequestClose} aria-label="Close"></button>
                        </div>
                        
                    </div>

                    <div className="modal-body p-5 pt-0">
                        <form onSubmit={onSubmit}>
                            <div className="row mb-3">
                                <div className="col input-group">
                                    <span className="input-group-text">Vorname:</span>
                                    <input
                                        type="text"
                                        name="first"
                                        value={inputs.first || ""}
                                        className="form-control"
                                        id="first_input"
                                        placeholder="Max"
                                        onChange={handleChange}
                                        required/>
                                </div>
                                <div className="col input-group">
                                    <span className="input-group-text">Nachname:</span>
                                    <input
                                        type="text"
                                        name="last"
                                        value={inputs.last || ""}
                                        className="form-control"
                                        id="last_input"
                                        placeholder="Mustermann"
                                        onChange={handleChange}
                                        required/>
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Gliederung:</span>
                                <select className="form-select"
                                    aria-label="Bezirk Esslingen"
                                    name="localGroup"
                                    id="localgroup_input"
                                    onChange={handleChange}
                                    required>
                                    <option value="Bezirk_Esslingen">Bezirk Esslingen</option>
                                    <option value="Aichtal">OG Aichtal</option>
                                    <option value="Altbach">OG Altbach</option>
                                    <option value="Berkheim">OG Berkheim</option>
                                    <option value="Bernhausen">OG Bernhausen</option>
                                    <option value="Esslingen">OG Esslingen</option>
                                    <option value="Kirchheim">OG Kirchheim</option>
                                    <option value="Neuffen_Beuren">OG Neuffen-Beuren</option>
                                    <option value="Nuertingen">OG Nürtingen</option>
                                    <option value="Ostfildern_Denkendorf">OG Ostfildern-Denkendorf</option>
                                    <option value="Weilheim">OG Weilheim</option>
                                    <option value="Wendlingen">OG Wendlingen</option>
                                    <option value="Wernau">OG Wernau</option>
                                </select>
                            </div>
                            
                            <div className="input-group mb-3">
                                    <span className="input-group-text">Geburtsdatum:</span>
                                    <input
                                        type="date"
                                        name="dateOfBirth"
                                        value={inputs.dateOfBirth || ""}
                                        className="form-control"
                                        id="dateofbirth_input"
                                        onChange={handleChange}
                                        required/>
                            </div>

                            <div className="row mb-3">
                                <div className="col input-group">
                                    <span className="input-group-text">Telefonnummer:</span>
                                    <input
                                        type="tel"
                                        name="phoneNumber"
                                        value={inputs.phoneNumber || ""}
                                        className="form-control"
                                        id="phonenumber_input"
                                        onChange={handleChange}
                                        required/>
                                </div>
                                <div className="col input-group">
                                    <span className="input-group-text">Threema-ID:</span>
                                    <input
                                        type="text"
                                        name="threemaID"
                                        value={inputs.threemaID || ""}
                                        className="form-control"
                                        id="threemaid_input"
                                        onChange={handleChange}
                                        required/>
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                    <span className="input-group-text">Emailadresse:</span>
                                    <input
                                        type="email"
                                        name="email"
                                        value={inputs.email || ""}
                                        className="form-control"
                                        id="email_input"
                                        onChange={handleChange}
                                        required/>
                            </div>

                            <div className="input-group mb-3">
                                    <span className="input-group-text">Notfall Telefonnummer:</span>
                                    <input
                                        type="tel"
                                        name="emergency_phoneNumber"
                                        value={inputs.emergency_phoneNumber || ""}
                                        className="form-control"
                                        id="emergency_phoneNumber_input"
                                        onChange={handleChange}
                                        required/>
                            </div>

                            <div className="row mb-3">
                                <div className="col input-group">
                                    <span className="input-group-text">DRSA:</span>
                                    <select className="form-select"
                                        aria-label="Juniorretter"
                                        name="drsa"
                                        id="drsa_input"
                                        onChange={handleChange}
                                        required>
                                        <option value="NOTHING">Kein DRSA</option>
                                        <option value="JUNIORRETTER">Juniorretter</option>
                                        <option value="BRONZE">DRSA Bronze</option>
                                        <option value="SILBER">DRSA Silber</option>
                                        <option value="GOLD">DRSA Gold</option>
                                    </select>
                                </div>
                                <div className="col input-group">
                                    <span className="input-group-text">Bildrechte:</span>
                                    <select className="form-select"
                                        aria-label="keine Bilder"
                                        name="imageRight"
                                        id="imageRight_input"
                                        onChange={handleChange}
                                        required>
                                        <option value="NO_IMAGES_ALLOWED">keine Bilder</option>
                                        <option value="ONLY_IMAGES_FOR_INTERN_USE_ALLOWED">Bilder nur intern</option>
                                        <option value="ALL_IMAGES_ALLOWED">Bilder öffentlich</option>
                                    </select>
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                <span className="input-group-text">Anmerkung:</span>
                                <input
                                    type="text"
                                    name="note"
                                    value={inputs.note || ""}
                                    className="form-control"
                                    id="note_input"
                                    onChange={handleChange}/>
                            </div>

                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Submit</button>
                        </form>
                    </div>
                    </div>
                </div>
            </Modal>
            */}

        </Modal>
    );
}

export default NewParticipantModal;