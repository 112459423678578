import {Toast, ToastContainer} from "react-bootstrap";

function ToastProvider({message, theme, show, onClose}) {


    return (
        <ToastContainer position="top-end" className="pt-9 pe-4">
            <Toast show={show} bg={theme} autohide onClose={onClose} delay={10000}>
                <Toast.Header closeButton closeVariant="white">
                    <strong className="me-auto">JET-Verwaltungssystem</strong>
                </Toast.Header>
                <Toast.Body>
                    {message}
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}

export default ToastProvider;