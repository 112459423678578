import React from "react";
import {Col, Container, InputGroup, Modal, Form, Row, Button} from "react-bootstrap";


function NewEventModal({show, onHide, onSubmit}) {
    const [inputs, setInputs] = React.useState({"compartment": "SONSTIGES"});
    const [validated, setValidated] = React.useState(false);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if(!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            onSubmit(inputs);
            setValidated(true);
            setInputs({"compartment": "SONSTIGES"})
        } 

        setValidated(true)
    }

    return(
        <Modal show={show} onHide={onHide} backdrop="static" size="lg">
            <Modal.Header closeButton className="bg-primary text-secondary border-primary">
                <Modal.Title>Erstelle eine neue Veranstaltung</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Container>
                        <Row>
                            <InputGroup as={Col}>
                                <InputGroup.Text id="labelTextDate">Datum</InputGroup.Text>
                                <Form.Control
                                    type="date"
                                    name="date"
                                    value={inputs.date || ""}
                                    id="date_input"
                                    onChange={handleChange}
                                    required/>
                            </InputGroup>
                            <InputGroup as={Col}>
                                <InputGroup.Text id="labelTextTitle">Title</InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    name="label"
                                    value={inputs.label || ""}
                                    id="label_input"
                                    placeholder="Titel (optional)"
                                    onChange={handleChange}
                                    required />
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text id="labelTextCompartment">Fachgruppe</InputGroup.Text>
                                <Form.Select
                                    aria-label="Wähle Fachgruppe"
                                    name="compartment"
                                    id="compartment_input"
                                    onChange={handleChange}
                                    required>
                                        <option value="SONSTIGES">Sonstiges</option>
                                        <option value="TAUCHEN">Einsatztauchen</option>
                                        <option value="STROEMUNGSRETTUNG">Strömungsrettung</option>
                                        <option value="BOOT">Boot</option>
                                        <option value="UNTERWASSERORTUNG">Unterwasserortung</option>
                                        <option value="IUK">IuK</option>
                                        <option value="EHSAN">EH-SAN</option>
                                        <option value="HIORGS">HiOrgs</option>
                                </Form.Select>
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text id="labelTextNote">Anmerkungen</InputGroup.Text>
                                <Form.Control 
                                    type="text"
                                    name="notes"
                                    value={inputs.notes || ""}
                                    id="notes_input"
                                    placeholder="Anmerkungen (optional)"
                                    onChange={handleChange}/>
                            </InputGroup>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-primary">
                    <Button type="submit" variant="secondary">Abschließen</Button>
                    <Button variant="primary" onClick={() => setInputs({"compartment": "SONSTIGES"})}>Felder leeren</Button>
                </Modal.Footer>
            </Form>

            {/*
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="Erfasse eine neue Veranstaltung"
                style={{
                    content: {
                        left: !isMobile ? "25%": "0%",
                        right: !isMobile ? "25%": "0%",
                        border: 0,
                        background: "none"
                    },
                    
                }}
                ariaHideApp={false}
            >
                
                <div className="modal-dialog" role="document">
                    <div className="modal-content rounded-4 shadow text-bg-light border border-black">
                    <div className="modal-header p-5 pb-4 border-bottom-0">
                        <h1 className="fw-bold mb-0 fs-2">Erfasse eine neue Veranstaltung</h1>
                        <div className="row"> 
                            <button type="button" className="col btn me-3 btn-lg rounded-3 btn-danger" style={{"--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem"}} onClick={() => {
                                setInputs({"compartment": "SONSTIGES", "addParticipants": false});
                            }} >CLEAR</button>
                            <button type="button" className="col btn-close my-auto" onClick={props.onRequestClose} aria-label="Close"></button>
                        </div>
                        
                    </div>


                    <div className="modal-body p-5 pt-0">
                        <form onSubmit={onSubmit}>

                                
                            <div className="row mb-3">
                                <div className="col input-group">
                                    <span className="input-group-text">Datum:</span>
                                    <input
                                        type="date"
                                        name="date"
                                        value={inputs.date || ""}
                                        className="form-control"
                                        id="date_input"
                                        onChange={handleChange}
                                        
                                        required/>
                                </div>
                                <div className="col input-group">
                                    <span className="input-group-text">Titel:</span>
                                    <input
                                        type="text"
                                        name="label"
                                        value={inputs.label || ""}
                                        className="form-control"
                                        id="label_input"
                                        placeholder="Titel (optional)"
                                        onChange={handleChange}/>
                                </div>
                            </div>

                            <div className="input-group mb-3">
                                    <span className="input-group-text">Fachgruppe:</span>
                                    <select className="form-select"
                                        aria-label="Wähle aus:"
                                        name="compartment"
                                        id="compartment_input"
                                        onChange={handleChange}
                                        required>
                                        <option value="SONSTIGES">Sonstiges</option>
                                        <option value="TAUCHEN">Einsatztauchen</option>
                                        <option value="STROEMUNGSRETTUNG">Strömungsrettung</option>
                                        <option value="BOOT">Boot</option>
                                        <option value="UNTERWASSERORTUNG">Unterwasserortung</option>
                                        <option value="IUK">IuK</option>
                                        <option value="EHSAN">EH-SAN</option>
                                        <option value="HIORGS">HiOrgs</option>
                                    </select>
                                </div>
                            
                            <div className="input-group mb-3">
                                    <span className="input-group-text">Anmerkungen:</span>
                                    <input
                                        type="text"
                                        name="notes"
                                        value={inputs.notes || ""}
                                        className="form-control"
                                        id="notes_input"
                                        placeholder="Anmerkungen"
                                        onChange={handleChange}/>
                            </div>

                            <div className="row mb-3">
                                <div className="col input-group form-check align-items-center">
                                    <input
                                        className="form-check-input" disabled
                                        name="participants"
                                        type="checkbox"
                                        checked={isAddPartiticpants}
                                        id="participants_input"
                                        onChange={() => {
                                            if(isAddPartiticpants) {
                                                setIsAddParticipants(false)
                                                setInputs(values => ({...values, ["participants"]: []}));
                                            }else {
                                                setIsAddParticipants(true)
                                            }

                                        }}
                                    />
                                    <label className="ps-2 form-check-label" htmlFor="mulitple_input">
                                        Teilnehmer hinzufügen?
                                    </label>
                                </div>
                                <div className={"col w-100" + (isAddPartiticpants ? "" : " invisible")}>
                                    <div className="row">
                                        {
                                            connection
                                            ?   
                                            <ReactSearchAutocomplete 
                                                items={participants}
                                                fuseOptions={{ keys: ["id", "name"] }} // Search on both fields
                                                resultStringKeyName="id" // String to display in the results
                                                onSelect={handleOnSelect}
                                                formatResult={formatResult}
                                                styling={{
                                                    height: "34px",
                                                    border: "1px solid",
                                                    borderRadius: "4px",
                                                    backgroundColor: "white",
                                                    boxShadow: "none",
                                                    fontSize: "12px",
                                                    fontFamily: "Courier",
                                                    clearIconMargin: "3px 8px 0 0",
                                                    zIndex: 2,
                                            }}/>
                                            :
                                            <span className="text-danger">NO CONNECTION OR FATAL ERROR</span>
                                        }   
                                    </div>
                                    <div className="row row-cols-auto">
                                        {Array.from({length: inputs.participants.length}, (_,i) =>
                                            {
                                                const p = inputs.participants[i]
                                                return (
                                                    <div className="col">
                                                        <div className="badge bg-secondary">
                                                            {p.id}
                                                            <div className="badge bg-danger ms-2" onClick={() => removeParticipant(p.id)}>X</div>
                                                        </div>
                                                    </div>)
                                            }
                                        )}
                                    </div>
                                </div>
                            </div>           

                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Submit</button>
                        </form>
                    </div>
                    </div>
                </div>
            </Modal>
            */}
            
        </Modal>
    );
}

export default NewEventModal;