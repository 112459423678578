import { Col, Container, Row } from "react-bootstrap";
import { TrashFill, ArrowUpRightSquareFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

function SingleEventConsultantListEntryView({bottom, removeConsultantFromEvent, consultant}) {

    const navigate = useNavigate();

    return (
        <Row className={bottom ? "" : "border-bottom"} key={"SingleEventConsultantListEntry" + consultant.cid}>
            <Col md="10">
                <h3>{consultant.first + " " + consultant.last}</h3>
            </Col>
            <Col md="2" className="align-self-center">
                <Row>
                    <Col>
                        <TrashFill size={25} color="red" onClick={() => removeConsultantFromEvent(consultant.cid)}/>
                    </Col>
                    <Col>
                        <ArrowUpRightSquareFill size={25} color="blue" onClick={() => {/*navigate("/consultants/"+consultant.cid)*/}} disabled/>
                    </Col>
                </Row>
            </Col>
        </Row>    
    )

}
export default SingleEventConsultantListEntryView;