import useAxiosPrivate from "../../jwt/useAxiosPrivate"
import React, { useState, useEffect } from "react"
import MultiSelectDropdown from "../../containers/custom/MultiSelectDropdown"
import { BucketFill, Plus } from 'react-bootstrap-icons';
import {toast} from "react-toastify";
import NewEquipmentModal from "./NewEquipmentModal"
import { Accordion, Breadcrumb, Nav, Navbar, Row, Table } from "react-bootstrap";
import ToastProvider from "../../containers/ToastProvider";

function Equipment(props) {
   
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = React.useState(false);
    const [connection, setConnection] = React.useState(true);
    const [noEntries, setNoEntries] = React.useState(false)
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [toastInfo, setToastInfo] = React.useState({show: false, message: "", theme: "success"});

    const equipment = props.equipment
    const dropDownColumnData = [
        {id:0, value: "ID"},
        {id:1, value: "Größe"},
        {id:2, value: "Zugeordnet"}
    ]

    const [dropDownColumnCheck, setDropDownColumnCheck] = useState(Array(3).fill(true))


    useEffect(() => {
        loadData();
    }, [])

    function changeCheckBoxColumnValue(i) {
        const list = [...dropDownColumnCheck];
        list[i] = !list[i]

        setDropDownColumnCheck(list)
    }

    async function loadData() {
        if(equipment.length === 0 && !noEntries) {
            setLoading(true)
        }
        await axiosPrivate.get("/equipment")
            .then((response) => {
                props.setEquipment(response.data)
                if(response.data.length === 0) {
                    setNoEntries(true)
                }else {
                    setNoEntries(false)
                }
                setConnection(true)
            })
            .catch(error => {
                if(error.code === "ERR_NETWORK") {
                    props.setEquipment([])
                    setConnection(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function addEquipment(formInput) {

        const content = {
            eid: null,
            equipmentType: formInput.equipmentType,
            size: formInput.size,
            uid: null
        }

        var response
        var status

        var toastMessage
        var toastTheme

        await axiosPrivate.post("/equipment/add", content)
            .then(res => response = res.data)
            .catch(function (error) {
                status = error.response.status
            })
        if(status === 403) {
            toastMessage = "Missing permission"
            toastTheme = "warning"
        }else {
            if(response.allowed !== 'false') { 
				toastMessage = response.message
                toastTheme = "danger"
			}else {
                toastMessage = "Ausrüstung wurde erfolgreich hinzugefügt!"
                toastTheme = "success"
			}
        }
        setModalOpen(false)
        await loadData();
        setToastInfo({show: true, message: toastMessage, theme: toastTheme})

    }
    async function addMultipleEquipment(formInput) {
        const content = {
            numberOfItems: formInput.numberOfItems,
            equipmentType: formInput.equipmentType,
            size: formInput.size,
            lowID: null,
            highID: null
        }

        var response
        var status

        var toastMessage
        var toastTheme

        await axiosPrivate.post("/equipment/addMultiple", content)
            .then(res => response = res.data)
            .catch(function (error) {
                status = error.response.status
            })
        if(status === 403) {
            toastMessage = "Missing permission"
            toastTheme = "warning"
        }else {
            if(response.allowed !== 'false') { 
				toastMessage = response.message
                toastTheme = "danger"
			}else {
                toastMessage = "Ausrüstungen wurde erfolgreich hinzugefügt!"
                toastTheme = "success"
			}
        }
        setModalOpen(false)
        await loadData();
        setToastInfo({show: true, message: toastMessage, theme: toastTheme})

    }

    return(
        <Row>

            {/*
            <NewConfirmModalProvider 
                    createModal={NewEquipmentModal}
                    confirmModal={ConfirmEquipmentModal}
                    onSubmit={(e,input) =>  {
                        if(input.multipleSelect) {
                            addMultipleEquipment(e, input)
                        }else {
                            addEquipment(e, input)
                        }
                    }}
                    openImpuls={openImpuls}
                    closeImpuls={closeImpuls}
                />
                */}

            <NewEquipmentModal show={isModalOpen} onHide={() => setModalOpen(false)} onSubmit={(inputs) => {inputs.numberOfItems===1 ? addEquipment(inputs) : addMultipleEquipment(inputs)}}/>

            <main role="main" className="ml-sm-auto col-md-9 col-xl-12 pt-3">

                <Breadcrumb className="ms-3">
                    <Breadcrumb.Item href="/equipments" className="text-body-secondary">
                        <BucketFill />
                    </Breadcrumb.Item>
                </Breadcrumb>    

                <ToastProvider show={toastInfo.show} message={toastInfo.message} theme={toastInfo.theme} onClose={() => setToastInfo({show: false, message: "", theme: "success"})}/>

                <div id="content">
                    <Navbar expand="lg" className="justify-content-between">
                        <Nav className="mr-auto">
                            <Nav.Item className="dropdown">
                                <MultiSelectDropdown text="Spalten ein-/ausschalten" data={dropDownColumnData} initialData={dropDownColumnCheck} onChange={(id) => changeCheckBoxColumnValue(id)} className="col"/>
                            </Nav.Item>
                        </Nav>
                        <Nav className="mr-auto">
                            <Nav.Item>
                                <button type="button" className="btn btn-success" onClick={() => setModalOpen(true)}><Plus size={20}></Plus></button>
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                    <Accordion defaultActiveKey="-1">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>Jacken</Accordion.Header>
                            <Accordion.Body style={{maxHeight: "48vh"}} className="overflow-auto scrollbarHideY">
                                <Table>
                                    <thead>
                                        <tr>
                                            {dropDownColumnCheck[0] ? <th>ID</th> : null}
                                            {dropDownColumnCheck[1] ? <th>Größe</th> : null}
                                            {dropDownColumnCheck[2] ? <th>Zugeordnet</th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.from({length: equipment.filter(e => e.equipmentType === "JACKET").length}, (_,i) => {
                                                const jackets = equipment.filter(e => e.equipmentType === "JACKET");

                                                return(
                                                    <tr>
                                                        {dropDownColumnCheck[0] ? <td>{jackets[i].eid}</td> : null }
                                                        {dropDownColumnCheck[1] ? <td>{jackets[i].size}</td> : null }
                                                        {dropDownColumnCheck[2] ? <td>{jackets[i].participant ? jackets[i].participant.uid + " - " + jackets[i].participant.first + " " + jackets[i].participant.last : ""}</td> : null }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>Hosen</Accordion.Header>
                            <Accordion.Body style={{maxHeight: "48vh"}} className="overflow-auto scrollbarHideY">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Größe</th>
                                            <th>Zugeordnet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.from({length: equipment.filter(e => e.equipmentType === "PANTS").length}, (_,i) => {
                                                const pants = equipment.filter(e => e.equipmentType === "PANTS");

                                                return(
                                                    <tr>
                                                        {dropDownColumnCheck[0] ? <td>{pants[i].eid}</td> : null }
                                                        {dropDownColumnCheck[1] ? <td>{pants[i].size}</td> : null }
                                                        {dropDownColumnCheck[2] ? <td>{pants[i].participant ? pants[i].participant.uid + " - " + pants[i].participant.first + " " + pants[i].participant.last : ""}</td> : null }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>T-Shirts</Accordion.Header>
                            <Accordion.Body style={{maxHeight: "48vh"}} className="overflow-auto scrollbarHideY">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Größe</th>
                                            <th>Zugeordnet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.from({length: equipment.filter(e => e.equipmentType === "SHIRT").length}, (_,i) => {
                                                const shirts = equipment.filter(e => e.equipmentType === "SHIRT");

                                                return(
                                                    <tr>
                                                        {dropDownColumnCheck[0] ? <td>{shirts[i].eid}</td> : null }
                                                        {dropDownColumnCheck[1] ? <td>{shirts[i].size}</td> : null }
                                                        {dropDownColumnCheck[2] ? <td>{shirts[i].participant ? shirts[i].participant.uid + " - " + shirts[i].participant.first + " " + shirts[i].participant.last : ""}</td> : null }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>Rückenschild</Accordion.Header>
                            <Accordion.Body style={{maxHeight: "48vh"}} className="overflow-auto scrollbarHideY">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Größe</th>
                                            <th>Zugeordnet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.from({length: equipment.filter(e => e.equipmentType === "PLATE").length}, (_,i) => {
                                                const plates = equipment.filter(e => e.equipmentType === "PLATE");

                                                return(
                                                    <tr>
                                                        {dropDownColumnCheck[0] ? <td>{plates[i].eid}</td> : null }
                                                        {dropDownColumnCheck[1] ? <td>{plates[i].size}</td> : null }
                                                        {dropDownColumnCheck[2] ? <td>{plates[i].participant ? plates[i].participant.uid + " - " + plates[i].participant.first + " " + plates[i].participant.last : ""}</td> : null }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>Qualifikationsabzeichen</Accordion.Header>
                            <Accordion.Body style={{maxHeight: "48vh"}} className="overflow-auto scrollbarHideY">
                                <Table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Größe</th>
                                            <th>Zugeordnet</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Array.from({length: equipment.filter(e => e.equipmentType === "BADGE").length}, (_,i) => {
                                                const badges = equipment.filter(e => e.equipmentType === "BADGE");

                                                return(
                                                    <tr>
                                                        {dropDownColumnCheck[0] ? <td>{badges[i].eid}</td> : null }
                                                        {dropDownColumnCheck[1] ? <td>{badges[i].size}</td> : null }
                                                        {dropDownColumnCheck[2] ? <td>{badges[i].participant ? badges[i].participant.uid + " - " + badges[i].participant.first + " " + badges[i].participant.last : ""}</td> : null }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </main>
            {/*
            <div className="ms-5 me-5 pt-2">

                <NewConfirmModalProvider 
                    createModal={NewEquipmentModal}
                    confirmModal={ConfirmEquipmentModal}
                    onSubmit={(e,input) =>  {
                        if(input.multipleSelect) {
                            addMultipleEquipment(e, input)
                        }else {
                            addEquipment(e, input)
                        }
                    }}
                    openImpuls={openImpuls}
                    closeImpuls={closeImpuls}
                />

                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <MultiSelectDropdown text="Spalten ein-/ausschalten" data={dropDownColumnData} initialData={dropDownColumnCheck} onChange={(id) => changeCheckBoxColumnValue(id)} className="col"/>
                        <MultiSelectDropdown text="Zeilen ein-/ausschalten" data={dropDownFilterData} initialData={dropDownFilterCheck} onChange={(id) => changeCheckBoxFilterValue(id)} className="col"/>
                    </div>
                    <button type="button" className="btn btn-success" onClick={() => setOpenImpuls(!openImpuls)}><Plus size={20}></Plus></button>
                </div>
                <ETable connection={connection} loading={loading} equipment={equipment} columnCheckList={dropDownColumnCheck} filterCheckList={dropDownFilterData.filter(e => dropDownFilterCheck[e.id]).map(e => e.enum)} />
            </div>
            */}
        </Row>
    )
}
export default Equipment;