import { TrashFill } from "react-bootstrap-icons";

function SingleParticipantEquipmentItems({list, removeEquip}) {

    return (
        <div className="d-flex flex-row">
            {
                Array.from({length: list.length}, (_,i) => {
                    if(i == list.length-1) {
                        return(
                            <>
                                <div className="pe-2">
                                    <h3>{list[i].text}</h3>
                                </div>
                                <div className="align-self-center">
                                    <div className="badge bg-danger">
                                        <TrashFill size={15} onClick={e => removeEquip(e, list[i].eid)}/>
                                    </div>
                                </div>
                            </>
                        )
                    }else {
                        return(
                            <>
                                <div className="pe-2">
                                    <h3>{list[i].text}</h3>
                                </div>
                                <div className="align-self-center pe-4">
                                    <div className="badge bg-danger">
                                        <TrashFill size={15} onClick={e => removeEquip(e, list[i].eid)}/>
                                    </div>
                                </div>
                            </>
                        )
                    }
                })
            }
        </div>
    )
}
export default SingleParticipantEquipmentItems;