import { BucketFill, Bucket, CalendarEventFill, CalendarEvent, PeopleFill, People, FileCodeFill, FileCode } from 'react-bootstrap-icons';
import {Link} from "react-router-dom";
import { useAuth } from '../jwt/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';

function Header() {

    const [loggedIn, setLoggedIn] = useState(false)

    const auth = useAuth();
    const navigate = useNavigate()
    const location = useLocation()

    const handleLogout = () => {
        auth.logout()
        navigate('/')
    }

    useEffect(() => {
        if(auth?.user) {
            setLoggedIn(true)
        }else {
            setLoggedIn(false)
        }
    },[auth?.user])


    const leadLocation = location.pathname.split('/')[1]

    return(            
        <Navbar collapseOnSelect expand='xl' sticky='top'style={{backgroundColor: "var(--bs-dlrg)"}}>
            <Container fluid>
                <Navbar.Brand className='py-0 mr-3'>
                    <Nav.Link as={Link} to="/participants">
                        <img src="/logo.svg" className="bi me-2" height="80" role="svg"/>
                    </Nav.Link>
                </Navbar.Brand>
                <Navbar.Collapse id="navbar-nav">
                    <div className='w-100 d-flex flex-wrap justify-content-xl-between align-items-center m-0'>
                        <Nav className='col-12 col-sm-auto p-0 flex-row justify-content-around justify-content-sm-start'>
                            <Nav.Item>
                                <Nav.Link className="text-white" eventKey="1" as={Link} to="/participants">
                                    <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                        {
                                            leadLocation === "participants"
                                            ?
                                            <PeopleFill  size={24}/>
                                            :
                                            <People  size={24}/>
                                        }
                                    </svg>
                                    Teilnehmer
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="text-white" eventKey="2" as={Link} to="/equipment">
                                    <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                        {
                                            leadLocation === "equipment"
                                            ?
                                            <BucketFill  size={24}/>
                                            :
                                            <Bucket  size={24}/>
                                        } 
                                    </svg>
                                    Ausrüstung
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link className="text-white" eventKey="3" as={Link} to="/events">
                                    <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                        {
                                            leadLocation === "events"
                                            ?
                                            <CalendarEventFill  size={24}/>
                                            :
                                            <CalendarEvent  size={24}/>
                                        } 
                                    </svg>
                                    Veranstaltungen
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Navbar.Collapse>
                <Nav className='order-xl-5 ml-auto mr-2 mx-xl-0' expand='xl'>
                    <Nav.Item className='order-5 d-flex align-items-center ml-2'>
                        {!loggedIn
                            ?
                                <Nav.Link className='text-white' as={Link} to="/login">
                                    <button type='button' className="btn btn-yellow me-2">Login</button>
                                </Nav.Link>
                            :
                                <button type='button' className="btn btn-yellow me-2" onClick={handleLogout}>Logout</button>
                        }
                    </Nav.Item>
                    <Nav.Item className='order-5 d-flex align-items-center ml-2'>
                        <Nav.Link className='text-white' as={Link} to="/specs">
                            <svg className='bi d-block mx-auto mb-1' width="28" height="28">
                                {
                                    leadLocation === "specs"
                                    ?
                                    <FileCodeFill  size={28}/>
                                    :
                                    <FileCode  size={28}/>
                                }
                            </svg>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Navbar.Toggle />
            </Container>


            {/*<div className='navbar navbar-expand-xl navbar-dark'>
            <Link className='navbar-brand py-0 mr-3' to="/participants">
                <img src="/logo.svg" className="bi me-2" height="80" role="svg"/>
            </Link>
            <ul className='order-xl-5 navbar-expand navbar-nav ml-auto mr-2 mx-xl-0'>
                <li className='nav-item order-5 d-flex align-items-center ml-2'>
                    {!loggedIn ?
                        <Link className="nav-link text-white" to="/login">
                            <button type='button' className="btn btn-yellow me-2">Login</button>
                        </Link>
                        :
                        <button type='button' className="btn btn-yellow me-2" onClick={handleLogout}>Logout</button>                               
                    }
                </li>
                <li className='nav-item order-5 d-flex align-items-center ml-2'>
                    <Link className="nav-link text-white" to="/specs">
                        <svg className='bi d-block mx-auto mb-1' width="28" height="28">
                            {
                                    leadLocation === "specs"
                                ?
                                <FileCodeFill  size={28}/>
                                :
                                <FileCode  size={28}/>
                            }
                        </svg>
                    </Link>
                </li>
            </ul>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div className='collapse navbar-collapse' id="navbarNav">
                <div className='w-100 d-flex flex-wrap justify-content-xl-between align-items-center m-0'>
                    <ul className='col-12 col-sm-auto p-0 navbar-nav flex-row justify-content-around justify-content-sm-start'>
                        <li className='nav-item px-2 px-xl-1'>
                            <Link className="nav-link text-white" to="/participants">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "participants"
                                        ?
                                        <PeopleFill  size={24}/>
                                        :
                                        <People  size={24}/>
                                    }
                                </svg>
                                Teilnehmer
                            </Link>
                        </li>
                        <li className='nav-item px-2 px-xl-1'>
                            <Link className="nav-link text-white" to="/equipment">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "equipment"
                                        ?
                                        <BucketFill  size={24}/>
                                        :
                                        <Bucket  size={24}/>
                                    } 
                                </svg>
                                Ausrüstung
                            </Link>
                        </li>
                        <li className='nav-item px-2 px-xl-1'>
                            <Link className="nav-link text-white" to="/events">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "events"
                                        ?
                                        <CalendarEventFill  size={24}/>
                                        :
                                        <CalendarEvent  size={24}/>
                                    } 
                                </svg>
                                Veranstaltungen
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>


        <div className="px-3 py-2 text-bg-dark border-bottom">
            <div className="container">
                <div className=" d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                    <Link className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none" to="#">
                        <img src="/logo.svg" className="bi me-2" height="80" role="svg"/>
                    </Link>

                    <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
                        <li>
                            <Link className="nav-link text-secondary" to="#" >
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === ""
                                        ?
                                        <HouseFill  size={24}/>
                                        :
                                        <House  size={24}/>
                                    }   
                                </svg>
                                Home
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link text-white" to="/participants">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "participants"
                                        ?
                                        <PeopleFill  size={24}/>
                                        :
                                        <People  size={24}/>
                                    }
                                </svg>
                                Teilnehmer
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link text-white" to="/equipment">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "equipment"
                                        ?
                                        <BucketFill  size={24}/>
                                        :
                                        <Bucket  size={24}/>
                                    } 
                                </svg>
                                Ausrüstung
                            </Link>
                        </li>
                        <li>
                            <Link className="nav-link text-white" to="/events">
                                <svg className="bi d-block mx-auto mb-1" width="24" height="24">
                                    {
                                        leadLocation === "events"
                                        ?
                                        <CalendarEventFill  size={24}/>
                                        :
                                        <CalendarEvent  size={24}/>
                                    } 
                                </svg>
                                Veranstaltungen
                            </Link>
                        </li>
                    </ul>
                    
                    <div className="text-end">
                        {!loggedIn ?
                            <Link className="nav-link text-white" to="/login">
                                <button type="button" className="btn btn-light text-dark me-2">Login</button>
                            </Link>
                            :
                            <button type="button" className="btn btn-light text-dark me-2" onClick={handleLogout}>Logout</button>                               
                        }     
                    </div>
                    <div className="text-end">
                        <Link className="nav-link text-white" to="/specs">
                            <svg className='bi d-block mx-auto mb-1' width="24" height="24">
                                {
                                    leadLocation === "specs"
                                    ?
                                    <FileCodeFill  size={24}/>
                                    :
                                    <FileCode  size={24}/>
                                }
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        */}

        </Navbar>
                   
    );
}

export default Header;