import { useNavigate } from "react-router-dom";
import { ArrowUpRightSquareFill } from "react-bootstrap-icons";
import { Container, Row, Col } from "react-bootstrap";

function SingleParticipantEventRowView({lastRow, event}) {

    const navigate = useNavigate()

    return (
        <Container fluid className={lastRow ? '' : 'border-bottom'}>
            <Row className="p-0">
                <Col xs={3}>
                    <span >{event.compartment}</span>
                </Col>
                <Col xs={6}>
                    <Row className="text-center">
                        <h4>{new Date(event.date).toLocaleDateString("de-DE")}</h4>
                    </Row>
                    <Row className="text-center">
                        <span>{event.label}</span>
                    </Row>
                </Col>
                <Col xs={3} className="text-end">
                    <ArrowUpRightSquareFill  size={28} color="blue" onClick={() => navigate("/events/"+event.evid)}/>
                </Col>
            </Row>

            {/*
            <div className={lastRow ? 'row m-0' : 'row border-bottom m-0'}>
                <div className='col-2'>
                    <span>{event.compartment}</span>
                </div>
                <div className='col-8'>
                    <div className='row text-center'>
                        <h4>{new Date(event.date).toLocaleDateString("de-DE")}</h4>
                    </div>
                    <div className='row text-center'>
                        <span>{event.label}</span>
                    </div>
                </div>
                <div className='col-2 align-self-center text-end'>
                    <ArrowUpRightSquareFill  size={28} color="blue" onClick={() => navigate("/events/"+event.evid)}/>
                </div>
            </div>
            */}
        </Container>
        
    )
}
export default SingleParticipantEventRowView;