import SingleParticipantEquipmentItems from "./SingleParticipantEquipmentItems";
import { PlusSquareFill } from "react-bootstrap-icons";
import React from 'react';
import AddEquipmentToParticipantModal from "./AddEquipmentToParticipantModal";
import { Card, Col, Row } from "react-bootstrap";

function SingleParticipantGenerallDetailView({participant, loading, connection, unknownError, found, removeEquip, modalAddEquipment}) {

    const [modalGenData, setModalGenData] = React.useState({open: false, typeText: "type", typeList: []})

    var birthdate = new Date(participant.dateOfBirth)
    var today  = new Date()
    var age = today.getFullYear() - birthdate.getFullYear()
    var m = today.getMonth() - birthdate.getMonth()
    if(m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age--;
    }
    birthdate = birthdate.toLocaleDateString("de-DE")

    var jacket = []
    var pants = []
    var shirt = []

    calcEquipment();

    function calcEquipment() {
        for ( const equipment of participant?.assignedEquipment) {
            if(equipment.equipmentType === "JACKET") {
                jacket.push({text: equipment.eid + "(Größe:" + equipment.size + ")", eid: equipment.eid})
            }
            if(equipment.equipmentType === "SHIRT") {
                shirt.push({text: equipment.eid + "(Größe:" + equipment.size + ")", eid: equipment.eid})
            }
            if(equipment.equipmentType === "PANTS") {
                pants.push({text: equipment.eid + "(Größe:" + equipment.size + ")", eid: equipment.eid})
            }
            if(equipment.equipmentType === "PLATE") {
                jacket.push({text: "Rückenschild", eid: equipment.eid})
            }
            if(equipment.equipmentType === "BADGE") {
                jacket.push({text: "Badge", eid: equipment.eid})
            }
        }
    }
    

    return (
        <>
            <AddEquipmentToParticipantModal 
                    participantName={participant.first + " " + participant.last}
                    isOpen={modalGenData.open}
                    equipmentTypeText={modalGenData.typeText}
                    equipmentTypeList={modalGenData.typeList}
                    onRequestClose={() => setModalGenData({open: false, name: modalGenData.name, typeText: modalGenData.typeText, typeList: modalGenData.typeList})}
                    onSubmit={(eid) => {modalAddEquipment(eid); setModalGenData({open: false, name: modalGenData.name, typeText: "", typeList: []})}}
            />
            {
                unknownError
                ?
                <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                :
                (
                    connection
                    ?
                    (
                        found
                        ?
                        <Card border="white" bg="dark-light">
                            <Card.Header>
                                <Row>
                                    <Col md="2">
                                        <span>uid: {loading ? "...loading..." : participant.uid}</span>
                                    </Col>
                                    <Col md="8" className="text-center">
                                        <h1>{loading ? "...loading..." : participant.first} {loading ? "...loading..." : participant.last}</h1>
                                    </Col>
                                    <Col md="2">
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body className="py-0">
                                <Row>
                                    <Col md="4">
                                        <Row>
                                            <span>Gliederung:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : participant.localGroup}</h3>
                                        </Row>
                                    </Col>
                                    <Col md="4" className="align-self-center text-center">
                                        <h3 className={age < 15 ? '' : (age > 16 ? 'text-danger' : 'text-warning')}>{loading ? "...loading..." : age} Jahre alt</h3>
                                    </Col>
                                    <Col md="4" className="text-end">
                                        <Row>
                                            <span>Geburtsdatum:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : birthdate}</h3>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="8">
                                        <Row>
                                            <span>Handynummer:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : participant.phoneNumber}</h3>
                                        </Row>
                                    </Col>
                                    <Col md="4" className="text-end">
                                        <Row>
                                            <span>Threema-ID:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : participant.threemaID}</h3>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <Row>
                                            <span>Email-Adresse:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : participant.email}</h3>
                                        </Row>
                                    </Col>
                                    <Col md="6" className="text-end">
                                        <Row>
                                            <span>Notfallnummer:</span>
                                        </Row>
                                        <Row>
                                            <h3>{loading ? "...loading..." : participant.emergencyPhoneNumber}</h3>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="border-top">
                                    <Col md="8" className="border-end">
                                        <Row>
                                            <Col md="11">
                                                <Row>
                                                    <span>Jacke + Rückenschild + Badge:</span>
                                                </Row>
                                                <SingleParticipantEquipmentItems list={jacket} removeEquip={removeEquip}/>
                                            </Col>
                                            <Col md="1" className="align-self-center">
                                                <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "Jacke/Rückenschild/Badge", typeList: ["JACKET", "PLATE", "BADGE"]})}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="11">
                                                <Row>
                                                    <span>T-Shirt:</span>
                                                </Row>
                                                    <SingleParticipantEquipmentItems list={shirt} removeEquip={removeEquip}/>
                                            </Col>
                                            <Col md="1" className="align-self-center">
                                                <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "T-Shirt", typeList: ["SHIRT"]})}/>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="11">
                                                <Row>
                                                    <span>hose:</span>
                                                </Row>
                                                <SingleParticipantEquipmentItems list={pants} removeEquip={removeEquip}/>
                                            </Col>
                                            <Col md="1" className="align-self-center">
                                                <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "Hose", typeList: ["PANTS"]})}/>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col md="4">
                                        <Row>
                                            <Col className="text-end">
                                                <Row>
                                                    <span>DRSA:</span>
                                                </Row>
                                                <Row>
                                                    <h3>{loading ? "...loading..." : (participant.drsa === "NOTHING" ? "-" : participant.drsa)}</h3>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className={"text-end" +(participant?.imageRight === "ALL_IMAGES_ALLOWED" ? ' bg-success' : (participant?.imageRight === "NO_IMAGES_ALLOWED" ? " bg-danger" : " bg-warning"))} style={{"--bs-bg-opacity": ".75"}}>
                                                <Row>
                                                    <span>Bildrechte:</span>
                                                </Row>
                                                <Row>
                                                    <h3>{loading ? "...loading..." : (participant.imageRight === "ALL_IMAGES_ALLOWED" ? 'Freigabe' : (participant?.imageRight === "NO_IMAGES_ALLOWED" ? " Keine Freigabe" : "Freigabe nur für intern"))}</h3>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md="5" className={"" + (participant.active ? 'bg-success' : 'bg-dark-light')} style={{"--bs-bg-opacity": ".75"}}>
                                                <Row>
                                                    <span>Aktivitätsstatus:</span>
                                                </Row>
                                                <Row>
                                                    <h3>{loading ? "...loading..." : (participant.active ? "Aktiv" : "Inaktiv")}</h3>
                                                </Row>
                                            </Col>
                                            <Col md="7" className="text-end">
                                                <Row>
                                                    <span>Teilnehmerrole:</span>
                                                </Row>
                                                <Row>
                                                    <h3>{loading ? "...loading..." : (participant.participantRole === "PARTICIPANT" ? "Teilnehmer" : "Helfer")}</h3>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                        :
                        <h2 className='text-center text-danger'>...EINTRAG NICHT GEFUNDEN...</h2>
                    )
                    :
                    <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                )
            }

            

        {/*
        <div className='border border-3 rounded-3'>

            <AddEquipmentToParticipantModal 
                participantName={participant.first + " " + participant.last}
                isOpen={modalGenData.open}
                equipmentTypeText={modalGenData.typeText}
                equipmentTypeList={modalGenData.typeList}
                onRequestClose={() => setModalGenData({open: false, name: modalGenData.name, typeText: modalGenData.typeText, typeList: modalGenData.typeList})}
                onSubmit={(eid) => {modalAddEquipment(eid); setModalGenData({open: false, name: modalGenData.name, typeText: "", typeList: []})}}
            />


                    {
                        unknownError
                        ?
                        <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                        :
                        (   connection
                            ?
                            (
                                found
                                ?
                                <>
                                    <div className='row border-bottom border-3 m-0'>
                                        <div className='col-4'>
                                            <span>uid: {loading ? "...loading..." : participant.uid}</span>
                                        </div>
                                        <div className='col-4 text-center'>
                                            <h1>{loading ? "...loading..." : participant.first} {loading ? "...loading..." : participant.last}</h1>
                                        </div>
                                        <div className='col-4'>
                                        </div>
                                    </div>
                                    <div className='row border-bottom border-3 m-0'>
                                        <div className='col-4 border-end'>
                                            <div className='row'>
                                                <span>Gliederung:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : participant.localGroup}</h3>
                                            </div>
                                        </div>
                                        <div className='col-4 align-self-center text-center'>
                                                <h3 className={age < 15 ? '' : (age > 16 ? 'text-danger' : 'text-warning')}>{loading ? "...loading..." : age} Jahre alt</h3>               
                                        </div>
                                        <div className='col-4 text-end border-start'>
                                            <div className='row'>
                                                <span>Geburtsdatum:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : birthdate}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-bottom border-3 m-0'>
                                        <div className='col-8 border-end'>
                                            <div className='row'>
                                                <span>Handynummer:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : participant.phoneNumber}</h3>
                                            </div>
                                        </div>
                                        <div className='col-4 text-end'>
                                            <div className='row'>
                                                <span>Threema-ID:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : participant.threemaID}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row border-bottom border-3 m-0'>
                                        <div className='col-6 border-end'>
                                            <div className='row'>
                                                <span>Email-Adresse:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : participant.email}</h3>
                                            </div>
                                        </div>
                                        <div className='col-6 text-end'>
                                            <div className='row'>
                                                <span>Notfallnummer:</span>
                                            </div>
                                            <div className='row'>
                                                <h3>{loading ? "...loading..." : participant.emergencyPhoneNumber}</h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row m-0'>
                                        <div className='col-8 border-end'>
                                            <div className='row border-bottom'>
                                                <div className='col-11'>
                                                    <div className='row'>
                                                        <span>Jacke + Rückenschild + Badge:</span>
                                                    </div>
                                                    <SingleParticipantEquipmentItems list={jacket} removeEquip={removeEquip}/>
                                                </div>
                                                <div className="col-1 align-self-center">
                                                    <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "Jacke/Rückenschild/Badge", typeList: ["JACKET", "PLATE", "BADGE"]})}/>
                                                </div>
                                            </div>
                                            <div className='row border-bottom'>
                                                <div className='col-11'>
                                                    <div className='row'>
                                                        <span>T-Shirt:</span>
                                                    </div>
                                                    <SingleParticipantEquipmentItems list={shirt} removeEquip={removeEquip}/>
                                                </div>
                                                <div className="col-1 align-self-center">
                                                    <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "T-Shirt", typeList: ["SHIRT"]})}/>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-11'>
                                                    <div className='row'>
                                                        <span>Hose:</span>
                                                    </div>
                                                    <SingleParticipantEquipmentItems list={pants} removeEquip={removeEquip}/>
                                                </div> 
                                                <div className="col-1 align-self-center">
                                                    <PlusSquareFill size={35} color="green" onClick={() => setModalGenData({open: true, typeText: "Hose", typeList: ["PANTS"]})}/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 border-start border-3'>
                                            <div className='row border-bottom'>
                                                <div className='col text-end'>
                                                    <div className='row'>
                                                        <span>DRSA:</span>
                                                    </div>
                                                    <div className='row'>
                                                        <h3>{loading ? "...loading..." : (participant.drsa === "NOTHING" ? "-" : participant.drsa)}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'row border-bottom' + (participant?.imageRight === "ALL_IMAGES_ALLOWED" ? ' bg-success' : (participant?.imageRight === "NO_IMAGES_ALLOWED" ? " bg-danger" : " bg-warning"))} style={{"--bs-bg-opacity": ".75"}}>
                                                <div className='col text-end'>
                                                    <div className='row'>
                                                        <span>Bildrechte:</span>
                                                    </div>
                                                    <div className='row'>
                                                        <h3>{loading ? "...loading..." : (participant.imageRight === "ALL_IMAGES_ALLOWED" ? 'Freigabe' : (participant?.imageRight === "NO_IMAGES_ALLOWED" ? " Keine Freigabe" : "Freigabe nur für intern"))}</h3>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className={'col-5 border-end' + (participant?.active ? ' bg-success' : ' bg-secondary')} style={{"--bs-bg-opacity": ".75"}}>
                                                    <div className='row text-start'>
                                                        <span>Aktivitätsstatus:</span>
                                                    </div>
                                                    <div className='row text-center'>
                                                        <h3>{loading ? "...loading..." : (participant.active ? "Aktiv" : "Inaktiv")}</h3>
                                                    </div>
                                                </div>
                                                <div className='col-7 text-end'>
                                                    <div className='row'>
                                                        <span>Teilnehmerrole:</span>
                                                    </div>
                                                    <div className='row'>
                                                        <h3>{loading ? "...loading..." : (participant.participantRole === "PARTICIPANT" ? "Teilnehmer" : "Helfer")}</h3>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                :
                                <h2 className='text-center text-danger'>...EINTRAG NICHT GEFUNDEN...</h2>
                            )
                            :
                            <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                            
                        )
                    } 
                </div> 
                */}
        </>
    )
}
export default SingleParticipantGenerallDetailView;