import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import SingleEventParticipantListView from "./SingleEventParticipantListView";
import SingleEventConsultantListView from "./SingleEventConsultantListView";
import { Breadcrumb, Card, Col, Row } from "react-bootstrap";
import { CalendarEventFill } from "react-bootstrap-icons";

function SingleEvent() {

    const {evid} = useParams()
    const axiosPrivate = useAxiosPrivate()

    
    const [data, setData] = React.useState({assignedEquipment: []})
    
    const [connection, setConnection] = React.useState(true)
    const [found, setFound] = React.useState(true)
    const [unknownError, setUnknownError] = React.useState(false)
    const [loading, setLoading] = React.useState(true);

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {

        var toastMessage
        var toastTheme

        setLoading(true)
        await axiosPrivate.get("/events/" + evid)
            .then((response) => {
                setData(response.data)
                setConnection(true)
                setFound(true)
                setUnknownError(false)
            })
            .catch(error => {
                if(error.response?.status === 404 || error.response?.status === 400) {
                    setFound(false)
                    setConnection(true)
                    setUnknownError(false)
                }else if(error.code === "ERR_NETWORK") {
                    setConnection(false)
                    setUnknownError(false)
                }else {
                    setUnknownError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            }) 

    }

    return(

        <div className="row">
            <main role="main" className="ml-sm-auto col-md-9 col-xl-12 pt-3">

                <Breadcrumb className="ms-3">
                    <Breadcrumb.Item href="/events">
                        <CalendarEventFill />
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/events/"+evid}>
                        {loading ? evid : data.label}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div className="content">
                    {
                        unknownError
                        ?
                        <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                        :
                        (
                            !connection
                            ?
                            <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                            :
                            (
                                !found
                                ?
                                <h2 className='text-center text-danger'>...EINTRAG NICHT GEFUNDEN...</h2>
                                :
                                (
                                    <Card border="white" bg="dark-light">
                                        <Card.Header>
                                            <Row>
                                                <Col md="2">
                                                    <span>evid: {loading ? "...loading..." : data.evid}</span>
                                                </Col>
                                                <Col md="8" className="text-center">
                                                    <h1>{loading ? "...loading..." : data.label}</h1>
                                                </Col>
                                                <Col md="2" className="text-end">
                                                    <Row>
                                                        <span>Teilnehmerzahl:</span>
                                                    </Row>
                                                    <Row>
                                                        <h3>{loading ? "...loading..." : data.participants.length}</h3>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Header>
                                        <Card.Body className="py-0">
                                            <Row className="border-bottom">
                                                <Col md="6" className="border-end">
                                                    <Row>
                                                        <Col>
                                                            <Row>
                                                                <span>Datum:</span>
                                                            </Row>
                                                            <Row>
                                                                <h3>{loading ? "...loading..." : new Date(data.date).toLocaleDateString("de-DE")}</h3>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <Row className="border-bottom">
                                                        <Col>
                                                            <Row>
                                                                <span>Fachgruppe:</span>
                                                            </Row>
                                                            <Row>
                                                                <h3>{loading ? "...loading..." : data.compartment}</h3>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                    <SingleEventConsultantListView evid={evid} event={data} reload={() => loadData()} />
                                                </Col>
                                                <Col md="6">
                                                    <SingleEventParticipantListView evid={evid} event={data} reload={() => loadData()} />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Row>
                                                        <span>Anmerkungen:</span>
                                                    </Row>
                                                    <Row>
                                                        <h3>{loading ? "...loading..." : (data.notes ? data.notes : "-")}</h3>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                )
                            )
                        )
                    }
                </div>

            </main>

        
            {/*
            <div className="border border-3 rounded-3 mt-3 mx-20">
                {
                    unknownError
                    ?
                    <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                    :
                    (   connection
                        ?
                        (
                            found
                            ?
                            <>
                                <div className='row border-bottom border-3 m-0'>
                                    <div className='col-2'>
                                        <span>evid: {loading ? "...loading..." : data.evid}</span>
                                    </div>
                                    <div className='col-8 text-center'>
                                        <h1>{loading ? "...loading..." : data.label}</h1>
                                    </div>
                                    <div className='col-2 text-end'>
                                        <div className="row">
                                            <span>Teilnehmerzahl:</span>
                                        </div>
                                        <div className="row">
                                            <h3>{loading ? "...loading..." : data.participants.length}</h3>
                                        </div>
                                    </div>
                                </div>
                                 <div className="row border-bottom border-3 m-0">  
                                    <div className="col-6 border-end">
                                        <div className="row border-bottom">
                                            <div className="col">
                                                <div className="row">
                                                    <span>Datum:</span>
                                                </div>
                                                <div className="row">
                                                    <h3>{loading ? "...loading..." : new Date(data.date).toLocaleDateString("de-DE")}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row border-bottom">
                                            <div className="col">
                                                <div className="row">
                                                    <span>Fachgruppe:</span>
                                                </div>
                                                <div className="row">
                                                    <h3>{loading ? "...loading..." : data.compartment}</h3>
                                                </div>
                                            </div>
                                        </div>
                                          <SingleEventConsultantListView evid={evid} event={data} reload={() => loadData()} />
                                    </div>
                                    <div className="col-6">
                                        <SingleEventParticipantListView evid={evid} event={data} reload={() => loadData()} />
                                    </div>
                                </div>
                                <div className="row m-0">
                                    <div className="col">
                                        <div className="row">
                                            <span>Anmerkungen:</span>
                                        </div>
                                        <div className="row">
                                            <h3>{loading ? "...loading..." : (data.notes ? data.notes : "-")}</h3>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            <h2 className='text-center text-danger'>...EINTRAG NICHT GEFUNDEN...</h2>
                        )
                        :
                        <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                        
                    )
                }           
            </div>
            */}
        </div>
    )
}

export default SingleEvent;