import { Col, Row } from "react-bootstrap";
import { TrashFill, ArrowUpRightSquareFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";

function SingleEventParticipantListEntryView({bottom, removeParticipantFromEvent, participant}) {

    const navigate = useNavigate();

    return(
        <Row className={bottom ? "" : "border-bottom"}  key={"SingleEventParticipantListEntry" + participant.uid}>
            <Col md="10">
                <h3>{participant.first + " " + participant.last}</h3>
            </Col>
            <Col md="2" className="align-self-center ">
                <Row>
                    <Col>
                        <TrashFill size={25} color="red" onClick={() => removeParticipantFromEvent(participant.uid)}/>
                    </Col>
                    <Col>
                        <ArrowUpRightSquareFill size={25} color="blue" onClick={() => navigate("/participants/"+participant.uid)}/>
                    </Col>
                </Row>             
            </Col>
        </Row>
    )
}
export default SingleEventParticipantListEntryView;