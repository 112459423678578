import TripleColorTableColumn from "../../containers/custom/TripleColorTableColumn";
import { useNavigate } from "react-router-dom";


function PTable(props) {

    const navigate = useNavigate();

    let tableBody
    if(props.participants != null) {
        tableBody = (
            <tbody>
                {Array.from({length: props.participants.length}, (_,i) =>
                {
                    let p = props.participants[i]
                    if(p != null) {
                        var birthdate = new Date(p.dateOfBirth)
                        var today  = new Date()
                        var age = today.getFullYear() - birthdate.getFullYear()
                        var m = today.getMonth() - birthdate.getMonth()
                        if(m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
                            age--;
                        }
                        birthdate = birthdate.toLocaleDateString("de-DE")

                        let tripleColor = {}
                        if(p.imageRight === "NO_IMAGES_ALLOWED") {
                            tripleColor.index = 2;
                            tripleColor.title = "Keine Bilder erlaubt"
                        } else if(p.imageRight === "ALL_IMAGES_ALLOWED") {
                            tripleColor.index = 0;
                            tripleColor.title = "Alle Bilder erlaubt"
                        } else {
                            tripleColor.index = 1;
                            tripleColor.title = "Bilder nur für interne Benutzung erlaubt"
                        }

                        return(
                            <tr key={i} onClick={() => navigate(""+ p.uid)} className={(p.participantRole === "TEAMER") ? "table-active" : (!p.active ? "table-warning" : "")}>
                                {props.checkList[0] ? <th scope="row">{p.uid}</th> : <></> }
                                {props.checkList[1] ? <td>{p.first}</td> : <></> }
                                {props.checkList[2] ? <td>{p.last}</td> : <></> }
                                {props.checkList[3] ? <td>{p.localGroup}</td> : <></> }
                                {props.checkList[4] ? <td>{birthdate}</td> : <></> }
                                {props.checkList[5] ? <td>{age}</td> : <></> }
                                {props.checkList[6] ? <td>{p.phoneNumber}</td> : <></> }
                                {props.checkList[7] ? <td>{p.email}</td> : <></> }
                                {props.checkList[8] ? <td>{p.threemaID}</td> : <></> }
                                {props.checkList[9] ? <td>{p.emergencyPhoneNumber}</td> : <></> }
                                {props.checkList[10] ? <td>{p.drsa === "NOTHING" ? "" : p.drsa}</td> : <></> }
                                {props.checkList[11] ? <td>{p.assignedEquipment.filter(eq => eq.equipmentType === "SHIRT")[0]?.size}</td> : <></> }
                                {props.checkList[12] ? <td>{p.assignedEquipment.filter(eq => eq.equipmentType === "PANTS")[0]?.size}</td> : <></> }
                                {props.checkList[13] ? <td>{p.assignedEquipment.filter(eq => eq.equipmentType === "JACKET")[0]?.eid}</td> : <></>}
                                {props.checkList[14] ? <TripleColorTableColumn index={tripleColor.index} title={tripleColor.title}/> : <></> }
                            </tr>
                        )
                    }else{
                        return(<></>)
                    }
                })}
            </tbody>
        );
    }

    let loadingBody = (
        <tbody>
            <tr key="1">
                <td colSpan={props.checkList.filter(i => i).length} className="text-center">...loading...</td>
            </tr>
            <tr key="2">
                <td colSpan={props.checkList.filter(i => i).length} className="text-center">...loading...</td>
            </tr>
            <tr key="3">
                <td colSpan={props.checkList.filter(i => i).length} className="text-center">...loading...</td>
            </tr>
        </tbody>
    )

    let noConnectionBody = (
        <tbody>
            <tr key="1">
                <td colSpan={props.checkList.filter(i => i).length} className="text-center text-danger">... NO CONNECTION TO BACKEND ...</td>
            </tr>
        </tbody>
    )

    let noEntriesBody = (
        <tbody>
            <tr key="1">
                <td colSpan={props.checkList.filter(i => i).length} className="text-center">... Noch keine Einträge vorhanden ...</td>
            </tr>
        </tbody>
    )

    return(
        <div className="rounded-3 text-bg-dark px-1 scrollbarHideY" style={{"maxHeight": "70vh"}}>
            <table className="table table-gray table-hover table-responsive">
                <thead style={{"position": "sticky", "top": 0}}>
                    <tr key="-1">
                        {props.checkList[0] ? <th scope="col">ID</th> : <></> }
                        {props.checkList[1] ? <th scope="col">Vorname</th> : <></> }
                        {props.checkList[2] ? <th scope="col">Nachname</th> : <></> }
                        {props.checkList[3] ? <th scope="col">OG</th> : <></> }
                        {props.checkList[4] ? <th scope="col">Geburtsdatum</th> : <></> }
                        {props.checkList[5] ? <th scope="col">Alter</th> : <></> }
                        {props.checkList[6] ? <th scope="col">Telefonnummer</th> : <></> }
                        {props.checkList[7] ? <th scope="col">Email</th> : <></> }
                        {props.checkList[8] ? <th scope="col">Threema-ID</th> : <></> }
                        {props.checkList[9] ? <th scope="col">Notfallnummer</th> : <></> }
                        {props.checkList[10] ? <th scope="col">DRSA</th> : <></> }
                        {props.checkList[11] ? <th scope="col">TShirt</th> : <></> }
                        {props.checkList[12] ? <th scope="col">Hose</th> : <></> }
                        {props.checkList[13] ? <th scope="col">Jacke</th> : <></> }
                        {props.checkList[14] ? <th scope="col">Bilderrecht</th> : <></> }
                    </tr>
                </thead>
                {
                    props.loading
                    ?
                    loadingBody
                    :
                    (props.connection
                        ?
                        (props.noEntries
                            ?
                            noEntriesBody
                            :
                            tableBody
                            )
                        :
                        noConnectionBody
                    )
                }
            </table>
        </div>
    );
}
export default PTable;