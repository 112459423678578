import {useState} from "react";
import { useAuth } from "../jwt/auth";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../jwt/axios";

export const Login = () => {
    const [user, setUser] = useState('')
    const [password, setPassword] = useState('')
    const [staySignedIn, setStaySignedIn] = useState(false)
    const auth = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const [connection, setConnection] = useState(true)

    const redirectPath = location.state?.from?.pathname || '/'

    const handleLogin = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post("/auth/authenticate", JSON.stringify({"email": user, "password": password}))
            console.log(response)

            if(!response) {
                setConnection(false)
                return
            }else {
                setConnection(true)               
            }

            console.log(JSON.stringify(response.data));
            const token = response?.data?.token;
            const role = response?.data?.role;
            auth.login(user, token, role, staySignedIn)
            setUser("")
            setPassword("")
            navigate(redirectPath, {replace: true})
        }catch(err) {
            if(err.code === "ERR_NETWORK") {
                setConnection(false)
            }else {
                console.log(err)
            }     
        }  
    }

    return (
        <div className="position-relative pt-5">
            <div className="position-absolute top-50 start-50 translate-middle-x w-50 border border-3 border-black rounded">
                <form className="m-3">
                    <div className="form-group">
                        <label htmlFor="inputEmail">Email address</label>
                        <input type="email" className="form-control" id="inputEmail" aria-describedby="email" placeholder="Enter email" onChange={(e) => setUser(e.target.value)} />
                    </div>
                    <div className="form-group">
                        <label htmlFor="inputPassword">Password</label>
                        <input type="password" className="form-control" id="inputPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="check" value={staySignedIn} onChange={(e) => setStaySignedIn(!staySignedIn)}/>
                        <label className="form-check-label" htmlFor="check">Angemeldet beleiben</label>
                    </div>
                    <button type="submit" className="btn btn-primary" onClick={handleLogin}>Anmelden</button>
                </form>
                {
                connection
                ?
                <></>
                :
                <div className="d-flex justify-content-center"><span className="text-danger">NO CONNECTION TO BACKEND</span></div>
            }
            </div>   
        </div>
 
    )
}