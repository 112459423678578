import React, {version, useEffect } from "react";
import axios from "../jwt/axios";
import packageJson from '../../package.json';

function Specs(props) {

    const specs = props.specs

    const [loading, setLoading] = React.useState(false)
    const [connection, setConnection] = React.useState(true)

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        if(specs.size === 0) {
            setLoading(true)
        }
        await axios.get("/specs")
            .then((response) => {
                props.setSpecs(response.data)
                setConnection(true)
            })
            .catch(error => {
                if(error.code === "ERR_NETWORK") {
                    props.setSpecs({})
                    setConnection(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })        
    }

    return (
        <div className="border border-3 border-black mt-5 mx-20 p-3">
            <b>Frontend with React.js hosted on Hostinger</b> <br />
            Frontend-Version: {packageJson.version} <br />
            React-Version: {version} <br />
            <br />
            <b>Backend with Spring Boot and Maven</b> <br />
            hosted on Hostinger VPS: api.hugohirling.com <br />
            Backend-Version: {
                loading
                ?
                "...loading..."
                :
                (   connection
                    ?
                    specs.springBootVersion
                    :
                    "...NO CONNECTION TO BACKEND..."
                )} <br />
            Backend-Java-Version: {
                loading
                ?
                "...loading..."
                :
                (   connection
                    ?
                    specs.javaVersion
                    :
                    "...NO CONNECTION TO BACKEND..."
                )} <br />
            <br />
            <b>MySQL Database hosted on Hostinger</b> <br />
        </div>
    )
}
export default Specs;