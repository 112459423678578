import {Typeahead} from 'react-bootstrap-typeahead'
import { useNavigate } from "react-router-dom"
import React, {useEffect, useRef} from "react"
import useAxiosPrivate from "../../jwt/useAxiosPrivate"
import { ArrowsFullscreen, PlusSquareFill, TrashFill } from "react-bootstrap-icons"
import SingleEventParticipantListEntryView from './SingleEventParticipantListEntryView'
import { Col, Row } from 'react-bootstrap'

function SingleEventParticipantListView({evid, event, reload}) {

    const [participants, setParticipants] = React.useState([])
    const [selectedParticipant, setSelectedParticipant] = React.useState(null);

    const [loading, setLoading] = React.useState(true);
    const [connection, setConnection] = React.useState(true)
    const [unknownError, setUnknownError] = React.useState(false)

    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();

    const typeaheadParticipantRef = useRef(null);

    useEffect(() => {
        loadParticipants();
    },[])

    async function loadParticipants() {
        await axiosPrivate.get("/participants/minimalOverview")
            .then((response) => {
                setParticipants(response.data)
                setConnection(true)
                setUnknownError(false)
            })
            .catch(error => {
                if(error.response?.status === 404 || error.response?.status === 400) {
                    setConnection(true)
                    setUnknownError(false)
                }else if(error.code === "ERR_NETWORK") {
                    setConnection(false)
                    setUnknownError(false)
                }else {
                    setUnknownError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    
    async function addParticipantToEvent(e) {
        e.preventDefault();

        if(event.participants.map(p => p.uid).includes(selectedParticipant) || selectedParticipant===null || selectedParticipant===undefined) {
            typeaheadParticipantRef.current.clear();
            return
        }

        await axiosPrivate.post("/events/" + evid + "/addParticipant/" + selectedParticipant)

        typeaheadParticipantRef.current.clear();
        await reload();
    }

    async function removeParticipantFromEvent(uid) {
        await axiosPrivate.post("/events/" + evid + "/removeParticipant/" + uid)
        
        await reload();
    }

    return (
        <Row>
            {
                unknownError
                ?
                <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                :
                (
                    !connection
                    ?
                    <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                    :
                    (
                        <Col>
                            <Row>
                                <span>Teilnehmer:</span>
                            </Row>
                            <Row>
                                {
                                    loading || event.participants === undefined
                                    ?
                                    <></>
                                    :
                                    <>
                                        <Col md="10">
                                            <Typeahead 
                                                id="typeahead-participants"
                                                labelKey={option => `${option.uid} - ${option.first} ${option.last}`}
                                                options={participants.filter(
                                                        p => !event.participants.map(pa => pa.uid).includes(p.uid)
                                                    )}
                                                onChange={(selected) => {setSelectedParticipant(selected[0]?.uid)}}
                                                ref={typeaheadParticipantRef}
                                                placeholder="Teilnehmer suchen"
                                            />
                                        </Col>  
                                        <Col md="2" className="align-self-center">
                                                <PlusSquareFill size={30} color="green" onClick={addParticipantToEvent}/>
                                        </Col>
                                    </>
                                }
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="11" className="scrollbarHideY" style={{maxHeight: "50vh"}}>
                                    {
                                        !loading && event.participants !== undefined
                                        ?
                                        (
                                            event.participants.length > 0
                                            ?
                                            Array.from({length: event.participants.length}, (_,i) => {
                                                return(
                                                    <SingleEventParticipantListEntryView participant={event.participants[i]} bottom={i === event.participants.length-1} removeParticipantFromEvent={removeParticipantFromEvent}/>    
                                                )
                                            })
                                            :
                                            <Row>
                                                <h3>-- keine Einträge --</h3>
                                            </Row>
                                        )
                                        :
                                        <Row>
                                            <h3>... loading ...</h3>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    )
                )
            }
        </Row>
    )
}
export default SingleEventParticipantListView;