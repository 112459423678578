import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./auth";

const PrivateRoutes = ({children, ...rest}) => {
    const auth = useAuth()
    const location = useLocation()

    return(
        auth.token ? <Outlet /> : <Navigate to="/login" state={{from: location}} replace/>
    )
}

export default PrivateRoutes