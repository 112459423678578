import { Card, ListGroup, Row, Col } from "react-bootstrap";
import SingleParticipantEventRowView from "./SingleParticipantEventRowView";

function SingleParticipantEventView({events=[], loading}) {

    return(

        <Card className="ms-4" border="white" bg="dark-light">
            <Card.Header>
                <Row className="text-center">
                    <Col md="2">
                    </Col>
                    <Col md="8" className="text-center align-self-center">
                        <h3>Veranstaltungen:</h3>
                    </Col>
                    <Col md="2">
                        <Row>
                            <span>Anzahl:</span>
                        </Row>
                        <Row>
                            <h4>{loading ? "...loading..." : events.length}</h4>
                        </Row>
                    </Col>
                </Row>
                
            </Card.Header>
            <Card.Body className="p-0">
                <ListGroup className="scrollbarHideY m-0" style={{maxHeight: "70vh"}}>
                    {
                        loading || events.length === 0
                        ?
                        <ListGroup.Item className="border-0 px-0" variant="dark-light">keine Einträge</ListGroup.Item>
                        :
                        Array.from({length: events.length}, (_,i) => {
                            const event = events[i]
                            const lastRow = (i === events.length-1);
                            return(
                                <ListGroup.Item className="border-0 px-0" variant="dark-light">
                                    <SingleParticipantEventRowView lastRow={lastRow} event={event}/>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </Card.Body>

            {/*
            <div className='ms-4 me-1 border border-3 rounded-3'>
                <div className='row text-center border-bottom border-3 m-0'>
                    <div className='col-2'>

                    </div>
                    <div className='col-8 text-center align-self-center'>
                        <h3>Veranstaltungen:</h3>
                    </div>
                    <div className='col-2'>
                        <div className="row">
                            <span>Anzahl:</span>
                        </div>
                        <div className="row">
                            <h4>{loading ? "...loading..." : events.length}</h4>
                        </div>
                    </div>      
                </div>
                <div className='col scrollbarHideY' style={{maxHeight: "70vh"}}>
                    {
                        loading || events.length === 0
                        ?
                        <div className='row text-center'><span>keine Einträge</span></div>
                        :
                            Array.from({length: events.length}, (_,i) => {        
                                const event = events[i]
                                const lastRow = i === events.length-1;
                                return(
                                    <SingleParticipantEventRowView lastRow={lastRow} event={event}/>
                                )   
                            })
                    }
                </div>
            </div>
            */}
        </Card>

        
    )
}
export default SingleParticipantEventView;