import React from "react";
import {Modal, Form, Container, Row, InputGroup, Button} from "react-bootstrap";
import { useEffect } from "react";

function NewEquipmentModal({show, onHide, onSubmit}) {
    const [inputs, setInputs] = React.useState({"equipmentType": "SHIRT", "size": ""});
    const [validated, setValidated] = React.useState(false);


    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if(!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            onSubmit(inputs);
            setValidated(true);
            setInputs({"equipmentType": "SHIRT", "size": ""})
        } 

        setValidated(true)
    }

    return(
        <Modal show={show} onHide={onHide} backdrop="static">
            <Modal.Header closeButton className="bg-primary text-secondary border-primary">
                <Modal.Title>Füge neue Ausrüstung hinzu</Modal.Title>
            </Modal.Header>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Modal.Body>
                    <Container>
                        <Row>
                            <InputGroup>
                                <InputGroup.Text id="labelTextNumber">Anzahl</InputGroup.Text>
                                <Form.Control
                                    type="number"
                                    name="numberOfItems"
                                    value={inputs.numberOfItems || ""}
                                    id="numberOfItems_input"
                                    placeholder="Anzahl"
                                    onChange={handleChange}
                                    required/>
                            </InputGroup>
                        </Row>
                        <Row className="mt-2">
                            <InputGroup>
                                <InputGroup.Text id="labelTextType">Ausrüstungsart</InputGroup.Text>
                                <Form.Select
                                    aria-label="Wähle aus:"
                                    name="equipmentType"
                                    id="equipmentType_input"
                                    onChange={handleChange}
                                    required>
                                        <option value="SHIRT">T-Shirt JET</option>
                                        <option value="PANTS">Einsatzhose</option>
                                        <option value="JACKET">Einsatzjacke</option>
                                        <option value="PLATE">Rückenschild JET</option>
                                        <option value="BADGE">Qualifikationsabzeichen JET</option>
                                </Form.Select>
                            </InputGroup>
                        </Row>
                        {
                            ["SHIRT", "PANTS", "JACKET"].includes(inputs.equipmentType)
                            ?
                            <Row className="mt-2">
                                <InputGroup>
                                    <InputGroup.Text id="labelTextSize">Größe</InputGroup.Text>
                                    <Form.Control
                                        type="text"
                                        name="size"
                                        value={inputs.size || ""}
                                        id="size_input"
                                        placeholder="Größe"
                                        onChange={handleChange}
                                        required={["SHIRT", "PANTS", "JACKET"].includes(inputs.equipmentType)}/>
                                </InputGroup>
                            </Row>
                            :
                            <></>
                        }    
                    </Container>
                </Modal.Body>
                <Modal.Footer className="border-primary">
                    <Button type="submit" variant="secondary">Abschließen</Button>
                    <Button variant="primary" onClick={() => setInputs({"equipmentType": "SHIRT", "size": ""})}>Felder leeren</Button>
                </Modal.Footer>
            </Form>
        
            {/*
            <Modal
                isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                contentLabel="Füge neue Ausrüstung hinzu"
                style={{
                    content: {
                        left: !isMobile ? "25%": "0%",
                        right: !isMobile ? "25%": "0%",
                        border: 0,
                        background: "none"
                    },
                    
                }}
                ariaHideApp={false}
            >
                
                <div className="modal-dialog" role="document">
                    <div className="modal-content rounded-4 shadow text-bg-light border border-black">
                    <div className="modal-header p-5 pb-4 border-bottom-0">
                        <h1 className="fw-bold mb-0 fs-2">Füge neue Ausrüstung hinzu</h1>
                        <div className="row"> 
                            <button type="button" className="col btn me-3 btn-lg rounded-3 btn-danger" style={{"--bs-btn-padding-y": ".25rem", "--bs-btn-padding-x": ".5rem", "--bs-btn-font-size": ".75rem"}} onClick={() => {
                                setInputs({"equipmentType": "SHIRT", "eid": "", "uid": "", "size": "", "multipleSelect": false});
                            }} >CLEAR</button>
                            <button type="button" className="col btn-close my-auto" onClick={props.onRequestClose} aria-label="Close"></button>
                        </div>
                        
                    </div>


                    <div className="modal-body p-5 pt-0">
                        <form onSubmit={onSubmit}>

                            <div className="row mb-3">
                                <div className="col input-group form-check align-items-center">
                                    <input
                                        className="form-check-input"
                                        name="multiple"
                                        type="checkbox"
                                        checked={multipleCheck}
                                        id="multiple_input"
                                        onChange={() => {
                                            if(multipleCheck) {
                                                setMultipleCheck(false)
                                            }else {
                                                setMultipleCheck(true)
                                            }
                                            setInputs(values => ({...values, ["multipleSelect"]: !multipleCheck}));
                                        }}
                                    />
                                    <label className="ps-2 form-check-label" htmlFor="mulitple_input">
                                        Mehrere Ausrüstungsstücke hinzufügen?
                                    </label>
                                </div>
                                <div className={"col input-group" + (multipleCheck ? "" : " invisible")}>
                                    <span className="input-group-text">Anzahl:</span>
                                    <input
                                        type="number"
                                        name="numberOfItems"
                                        value={inputs.numberOfItems || ""}
                                        className="form-control"
                                        id="numberOfItems_input"
                                        placeholder="Anzahl"
                                        onChange={handleChange}
                                        required={multipleCheck}/>
                                </div>
                            </div>
                                
                            <div className="row mb-3">
                                <div className={"col input-group" + (!multipleCheck ? "" : " invisible")}>
                                    <span className="input-group-text">EID:</span>
                                    <input
                                        type="number"
                                        name="eid"
                                        value={inputs.eid || ""}
                                        className="form-control"
                                        id="eid_input"
                                        placeholder="EID (optional)"
                                        onChange={handleChange}/>
                                </div>
                                <div className="col input-group">
                                    <span className="input-group-text">Ausrüstungsart:</span>
                                    <select className="form-select"
                                        aria-label="Wähle aus:"
                                        name="equipmentType"
                                        id="equipmentType_input"
                                        onChange={handleChange}
                                        required>
                                        <option value="SHIRT">T-Shirt JET</option>
                                        <option value="PANTS">Einsatzhose</option>
                                        <option value="JACKET">Einsatzjacke</option>
                                        <option value="PLATE">Rückenschild JET</option>
                                        <option value="BADGE">Qualifikationsabzeichen JET</option>
                                    </select>
                                </div>
                            </div>
                            
                            <div className="input-group mb-3">
                                    <span className="input-group-text">Größe:</span>
                                    <input
                                        type="text"
                                        name="size"
                                        value={inputs.size || ""}
                                        className="form-control"
                                        id="size_input"
                                        placeholder="Größe (optional)"
                                        onChange={handleChange}/>
                            </div>

                            {
                                !multipleCheck
                                ?
                                <div className="row mb-3">
                                    <div className="col input-group form-check align-items-center">
                                        <input
                                            className="form-check-input"
                                            name="assign"
                                            type="checkbox"
                                            checked={assignedCheck}
                                            id="assign_input"
                                            onChange={() => {
                                                if(assignedCheck) {
                                                    setAssignedCheck(false)
                                                }else {
                                                    setAssignedCheck(true)
                                                }
                                            }}
                                        />
                                        <label className="ps-2 form-check-label" htmlFor="assign_input">
                                            Ausrüstung zuweisen
                                        </label>
                                    </div>
                                    <div className={"col input-group" + (assignedCheck ? "" : " invisible")}>
                                        <span className="input-group-text">Teilnehmer-ID:</span>
                                        <input
                                            type="number"
                                            name="uid"
                                            value={inputs.uid || ""}
                                            className="form-control"
                                            id="uid_input"
                                            placeholder="Teilnehmer-ID (optional)"
                                            onChange={handleChange}/>
                                    </div>
                                </div>
                                :
                                <div className="row mb-3">
                                    <div className="col input-group">
                                        <span className="input-group-text">Kleinste EID:</span>
                                        <input
                                            type="number"
                                            name="lowID"
                                            value={inputs.lowID || ""}
                                            className="form-control"
                                            id="lowID_input"
                                            placeholder="Kleinste EID (optional)"
                                            onChange={handleChange}/>
                                    </div>
                                    <div className="col input-group">
                                        <span className="input-group-text">Größte EID:</span>
                                        <input
                                            type="number"
                                            name="highID"
                                            value={inputs.highID || ""}
                                            className="form-control"
                                            id="highID_input"
                                            placeholder="Größte EID (optional)"
                                            onChange={handleChange}/>
                                    </div>
                                </div>
                            }
                            

                        <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Submit</button>
                        </form>
                    </div>
                    </div>
                </div>
            </Modal>
            */}
        </Modal>
    );
}

export default NewEquipmentModal;