import MultiSelectDropdown from "../../containers/custom/MultiSelectDropdown";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import React from "react";
import { useEffect } from "react";
import { CalendarEventFill, Plus } from 'react-bootstrap-icons';
import {toast} from "react-toastify";
import NewEventModal from "./NewEventModal";
import { Accordion, Breadcrumb, Col, Nav, Navbar, Table, Row, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import ToastProvider from "../../containers/ToastProvider";

function Events(props) {

    const axiosPrivate = useAxiosPrivate()
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);
    const [connection, setConnection] = React.useState(true);
    const [noEntries, setNoEntries] = React.useState(false);
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [toastInfo, setToastInfo] = React.useState({show: false, message: "", theme: "success"});

    const dropDownData = [
        {id:0, value: "EVID"},
        {id:1, value: "Datum"},
        {id:5, value: "Titel"},
        {id:2, value: "Abteilung"},
        {id:3, value: "Teilnehmerzahl"},
        {id:4, value: "Anmerkung"},
    ]

    const [dropDownCheck, setDropDownCheck] = React.useState([false, true, true, true, true, true])

    const events = props.events;
    const nextEvent = events?.filter(ev => new Date(ev.date).getTime() > Date.now()).sort((a,b) => new Date(a.date)-new Date(b.date))[0]

    useEffect(() => {
        loadData();
    }, [])

    function changeCheckBoxValue(i) {
        const list = [...dropDownCheck];
        list[i] = !list[i]

        setDropDownCheck(list)
    }

    async function loadData() {
        if(events.length === 0 && !noEntries) {
            setLoading(true)
        }
        await axiosPrivate.get("/events")
            .then((response) => {
                props.setEvents(response.data)
                if(response.data.length === 0) {
                    setNoEntries(true)
                }else {
                    setNoEntries(false)
                }
                setConnection(true)
            })
            .catch(error => {
                if(error.code === "ERR_NETWORK") {
                    props.setEvents([])
                    setConnection(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }
    async function addEvent(formInput) {
        const content = {
            date: formInput.date,
            label: formInput.label,
            compartment: formInput.compartment,
            notes: formInput.notes,
            consultants: [],
            participants: []
        }

        var response
        var status

        var toastMessage
        var toastTheme

        await axiosPrivate.post("/events/add", content)
            .then(res => response = res.data)
            .catch(function (error) {
                status = error.response?.status
            })
        if(status === 403) {
            toastMessage = "Missing permission"
            toastTheme = "warning"
        }else {
            if(response.allowed !== 'false') { 
				toastMessage = response.message
                toastTheme = "danger"
			}else {
				toastMessage = "Veranstaltung wurde erfolgreich hinzugefügt!"
                toastTheme = "success"
			}
        }
        setModalOpen(false);
        await loadData();
        setToastInfo({show: true, message: toastMessage, theme: toastTheme})

    }

    return(
        <div className="row">

            {/*
            <NewConfirmModalProvider 
                    createModal={NewEventModal}
                    confirmModal={ConfirmEventModal}
                    onSubmit={(e,input) => addEvent(e, input)}
                    openImpuls={openImpuls}
                    closeImpuls={closeImpuls}
                />
                 */}

            <NewEventModal show={isModalOpen} onHide={() => setModalOpen(false)} onSubmit={(inputs) => addEvent(inputs)} />


            <main role="main" className="ml-sm-auto col-md-9 col-xl-12 pt-3">
                <Breadcrumb className="ms-3">
                    <Breadcrumb.Item href="/equipments" className="text-body-secondary">
                        <CalendarEventFill />
                    </Breadcrumb.Item>
                </Breadcrumb>

                <ToastProvider show={toastInfo.show} message={toastInfo.message} theme={toastInfo.theme} onClose={() => setToastInfo({show: false, message: "", theme: "success"})}/>

                <div className="content">
                    <Navbar expand="lg" className="justify-content-between">
                        <Nav className="mr-auto">
                            <Nav.Item className="dropdown">
                                <MultiSelectDropdown text="Spalten ein-/ausschalten" initialData={dropDownCheck} data={dropDownData} onChange={(id) => changeCheckBoxValue(id)} className="pe-3"/>
                            </Nav.Item>
                        </Nav>
                        <Nav className="mr-auto">
                            <Nav.Item>
                                <button type="button" className="btn btn-success" onClick={() => setModalOpen(true)}><Plus size={20}></Plus></button>
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                    <Row>
                        <Col md="4">
                            <Card border="white" bg="dark-light">
                                <Card.Header className="text-center"><h3>Nächste Veranstaltung</h3></Card.Header>
                                <Card.Body>
                                    {
                                        loading
                                        ?
                                            <Card.Title>loading</Card.Title>
                                        :
                                        (!connection
                                        ?
                                            <Card.Title>no connection to backend</Card.Title>
                                        :
                                            <>
                                                <Card.Title>{nextEvent ? nextEvent.label : ""}</Card.Title>
                                                <Card.Title>{nextEvent ? new Date(nextEvent.date).toLocaleDateString("de-DE") : ""}</Card.Title>
                                                <Card.Text>
                                                    <Row>
                                                        <Col md="3">
                                                            <span>Abteilung:</span>
                                                        </Col>
                                                        <Col md="9">
                                                            <span>{nextEvent ? nextEvent.compartment : ""}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="3">
                                                            <span>Teilnehmer:</span>
                                                        </Col>
                                                        <Col md="9">
                                                            <span>{nextEvent ? nextEvent.participants.length : ""}</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="3">
                                                            <span>Anmerkung:</span>
                                                        </Col>
                                                        <Col md="9">
                                                            <span>{nextEvent ? nextEvent.note : ""}</span>
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                                {nextEvent ? <Card.Link onClick={() => navigate(""+ nextEvent.evid)}>Vollansicht</Card.Link> : <></>}
                                            </>
                                        )}
                                    
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="8">
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>zukünftige Veranstaltungen</Accordion.Header>
                                    <Accordion.Body style={{maxHeight: "45vh"}} className="overflow-auto scrollbarHideY">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    {dropDownCheck[0] ? <th scope="col">EVID</th> : <></> }
                                                    {dropDownCheck[1] ? <th scope="col">Datum</th> : <></> }
                                                    {dropDownCheck[5] ? <th scope="col">Titel</th> : <></> }
                                                    {dropDownCheck[2] ? <th scope="col">"Abteilung"</th> : <></> }
                                                    {dropDownCheck[3] ? <th scope="col">Teilnehmer</th> : <></> }
                                                    {dropDownCheck[4] ? <th scope="col">Anmerkung</th> : <></> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Array.from({length: events.filter(ev => new Date(ev.date).getTime() > Date.now()).length}, (_,i) => {
                                                        const eventList = events.filter(ev => new Date(ev.date).getTime() > Date.now()).sort((a,b) => new Date(a.date)-new Date(b.date));

                                                        return(
                                                            <tr key={i} onClick={() => navigate(""+ eventList[i].evid)}>
                                                                {dropDownCheck[0] ? <td>{eventList[i].evid}</td> : <></> }
                                                                {dropDownCheck[1] ? <td>{new Date(eventList[i].date).toLocaleDateString("de-DE")}</td> : <></> }
                                                                {dropDownCheck[5] ? <td>{eventList[i].label}</td> : <></> }
                                                                {dropDownCheck[2] ? <td>{eventList[i].compartment}</td> : <></> }
                                                                {dropDownCheck[3] ? <td>{eventList[i].participants.length}</td> : <></>}
                                                                {dropDownCheck[4] ? <td>{eventList[i].note}</td> : <></> }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>vergangene Veranstaltungen</Accordion.Header>
                                    <Accordion.Body style={{maxHeight: "45vh"}} className="overflow-auto scrollbarHideY">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    {dropDownCheck[0] ? <th scope="col">EVID</th> : <></> }
                                                    {dropDownCheck[1] ? <th scope="col">Datum</th> : <></> }
                                                    {dropDownCheck[5] ? <th scope="col">Titel</th> : <></> }
                                                    {dropDownCheck[2] ? <th scope="col">"Abteilung"</th> : <></> }
                                                    {dropDownCheck[3] ? <th scope="col">Teilnehmer</th> : <></> }
                                                    {dropDownCheck[4] ? <th scope="col">Anmerkung</th> : <></> }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Array.from({length: events.filter(ev => new Date(ev.date).getTime() < Date.now()).length}, (_,i) => {
                                                        const eventList = events.filter(ev => new Date(ev.date).getTime() < Date.now()).sort((a,b) => new Date(b.date)-new Date(a.date));

                                                        return(
                                                            <tr key={i} onClick={() => navigate(""+ eventList[i].evid)}>
                                                                {dropDownCheck[0] ? <td>{eventList[i].evid}</td> : <></> }
                                                                {dropDownCheck[1] ? <td>{new Date(eventList[i].date).toLocaleDateString("de-DE")}</td> : <></> }
                                                                {dropDownCheck[5] ? <td>{eventList[i].label}</td> : <></> }
                                                                {dropDownCheck[2] ? <td>{eventList[i].compartment}</td> : <></> }
                                                                {dropDownCheck[3] ? <td>{eventList[i].participants.length}</td> : <></>}
                                                                {dropDownCheck[4] ? <td>{eventList[i].note}</td> : <></> }
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                    
                </div>

            </main>
            {/*
            <div className="ms-5 me-5 pt-2">

                <NewConfirmModalProvider 
                    createModal={NewEventModal}
                    confirmModal={ConfirmEventModal}
                    onSubmit={(e,input) => addEvent(e, input)}
                    openImpuls={openImpuls}
                    closeImpuls={closeImpuls}
                />




                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <MultiSelectDropdown text="Spalten ein-/ausschalten" initialData={dropDownCheck} data={dropDownData} onChange={(id) => changeCheckBoxValue(id)} className="pe-3"/>
                        <MultiSelectDropdown text="Zeilen ein-/ausschalten" data={dropDownFilterData} initialData={dropDownFilterCheck} onChange={(id) => changeCheckBoxFilterValue(id)} className="col"/>
                    </div>       
                    <button type="button" className="btn btn-success" onClick={() => setOpenImpuls(!openImpuls)}><Plus size={20}></Plus></button>
                </div>
                <EvTable noEntries={noEntries} connection={connection} loading={loading} events={events?.sort(function(a,b) {return new Date(b.date)-new Date(a.date)})} checkList={dropDownCheck}/>
            </div>
            */}
        </div>
    )
}
export default Events;