import { SquareFill } from "react-bootstrap-icons";

const TripleColorTableColumn = (props) => {

    let square;
    if(props.index === 0) {
        square = <SquareFill color="green" size={props.size ? props.size : 24} title={props.title}/>
    }
    else if(props.index === 1) {
        square = <SquareFill color="orange" size={props.size ? props.size : 24} title={props.title}/>
    }
    else {
        square = <SquareFill color="red" size={props.size ? props.size : 24} title={props.title}/>
    }

    return (
        <td className="text-center">
            {square}
        </td>
    );
};

export default TripleColorTableColumn;