import React, {useEffect} from "react";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import { ArrowLeftRight, PersonWorkspace } from "react-bootstrap-icons";
import { Card, Col, Row } from "react-bootstrap";

function SingleParticipantActivenessView({uid, participant, loading, loadHead}) {

    const [data, setData] = React.useState([])

    const axiosPrivate = useAxiosPrivate();

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        await axiosPrivate.get("/participants/calcActiveness/"+uid)
            .then((response) => {
                setData(response.data)
            })
    }

    async function changeActiveStatus(e, bool) {
        e.preventDefault();

        await axiosPrivate.post("/participants/changeActiveStatus/"+uid, bool)
        
        await loadHead();
    }

    async function turnToConsultant(e) {
        e.preventDefault();

        if(!window.confirm("Willst du für " + participant.first + " einen Referentenaccount anlegen?")) {
            return;
        }

        await axiosPrivate.post("/participants/turnToConsultant/"+uid)

        await loadHead();
    }

    var birthdate = new Date(participant.dateOfBirth)
    var today  = new Date()
    var age = today.getFullYear() - birthdate.getFullYear()
    var m = today.getMonth() - birthdate.getMonth()
    if(m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age--;
    }

    return(
        <Card border="white" bg="dark-light" className="mt-3">
            <Card.Header className="text-center">
                <h3>berechneter Aktivitätszustand</h3>
            </Card.Header>
            <Card.Body className="p-0">
                <Row>
                    <Col md="5">
                        <Row className="text-center">
                            <Col md="3">
                                <span>Ausrüstung</span>
                            </Col>
                            <Col md="9">
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.hasEquipment
                                        ?
                                        <div className='text-bg-blue'>
                                            Besitzt
                                        </div>
                                        :
                                        <div className='text-bg-dark-light'>
                                            Besitzt nicht
                                        </div>
                                    )
                                }
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col md="3">
                                <span>Alter</span>
                            </Col>
                            <Col md="9">
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.ageStatus === "ACCEPTED"
                                        ?
                                        <div className='text-bg-success'>
                                            {age} Jahre
                                        </div>
                                        :
                                        (
                                            data.ageStatus === "ON_EDGE"
                                            ?
                                            <div className='text-bg-warning'>
                                                {age} Jahre
                                            </div>
                                            :
                                            <div className='text-bg-danger'>
                                                {age} Jahre
                                            </div>
                                        )  
                                    )
                                }
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col md="3">
                                <span>Abwesend</span>
                            </Col>
                            <Col md="9">
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.eventStatus === "LESS_THAN_THREE_MONTHS"
                                        ?
                                        <div className='text-bg-success'>
                                            &lt; 3 Monate
                                        </div>
                                        :
                                        (
                                            data.eventStatus === "THREE_MONTHS"
                                            ?
                                            <div className='text-bg-success bg-opacity-75'>
                                                = 3 Monate
                                            </div>
                                            :
                                            (
                                                data.eventStatus === "FOUR_MONTHS"
                                                ?
                                                <div className='text-bg-danger bg-opacity-10'>
                                                    = 4 Monate
                                                </div>
                                                :
                                                (
                                                    data.eventStatus === "FIVE_MONTHS"
                                                    ?
                                                    <div className='text-bg-danger bg-opacity-25'>
                                                        = 5 Monate
                                                    </div>
                                                    :
                                                    (
                                                        data.eventStatus === "SIX_MONTHS"
                                                        ?
                                                        <div className='text-bg-danger bg-opacity-50'>
                                                            = 6 Monate
                                                        </div>
                                                        :
                                                        <div className='text-bg-danger bg-opacity-75'>
                                                            &gt; 6 Monate
                                                        </div>
                                                    )
                                                )
                                            )
                                        )  
                                    )
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col md="3">
                    </Col>
                    <Col md="3">
                        <Row className="text-center">
                            <span>vorgeschlagener Status:</span>
                        </Row>
                        <Row>
                            {
                                loading
                                ?
                                <h4>...loading...</h4>
                                :
                                (
                                    data.allowedInactive && data.suggestInactive
                                    ?
                                    <Col className="text-center">
                                        <Row className="text-bg-danger">
                                            <Col md="10" className="align-self-center">
                                                <h4>Inaktiv</h4>
                                            </Col>
                                            {
                                                data.suggestInactive === participant.active
                                                ?
                                                <Col md="2" className="align-self-center">
                                                    <ArrowLeftRight size={24} color="black" title="Ändere Aktivitätsstatus" onClick={e => changeActiveStatus(e, false)}/>
                                                </Col>
                                                :
                                                <></>
                                            }
                                        </Row>
                                    </Col>
                                    :
                                    <Col className="text-center">
                                        <Row className="text-bg-blue">
                                            <Col md="10" className="align-self-center">
                                                <h4>Aktiv</h4>
                                            </Col>
                                            {
                                                data.suggestInactive === participant.active
                                                ?
                                                <Col md="2" className="align-self-center">
                                                    <ArrowLeftRight size={24} color="black" title="Ändere Aktivitätsstatus" onClick={e => changeActiveStatus(e, true)}/>
                                                </Col>
                                                :
                                                <></>
                                            }     
                                        </Row>
                                    </Col>
                                )
                            }
                        </Row>
                    </Col>
                    {
                        participant.nextConsultant
                        ?
                        <></>
                        :
                        <Col md="1" className="text-center align-self-center">
                            <PersonWorkspace size={35} title="Erzeuge Teamer/Referentenaccount" onClick={e => turnToConsultant(e)}/>
                        </Col>                  
                    }
                </Row>
            </Card.Body>
            {/*
            <div className='mt-3 border border-3 rounded-3'>
                <div className='row border-bottom text-center m-0'>
                    <h3>berechneter Aktivitätszustand</h3>
                </div>
                <div className='row m-0'>
                    <div className='col-4 border-end'>
                        <div className='row border-bottom text-center'>
                            <div className='col-3'>
                                <span>Ausrüstung</span>
                            </div>
                            <div className='col-9 pe-0'>
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.hasEquipment
                                        ?
                                        <div className='text-bg-primary'>
                                            Besitzt
                                        </div>
                                        :
                                        <div className='text-bg-secondary'>
                                            Besitzt nicht
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div className='row border-bottom text-center'>
                            <div className='col-3'>
                                <span>Alter</span>
                            </div>
                            <div className='col-9 pe-0'>
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.ageStatus === "ACCEPTED"
                                        ?
                                        <div className='text-bg-success'>
                                            {age} Jahre
                                        </div>
                                        :
                                        (
                                            data.ageStatus === "ON_EDGE"
                                            ?
                                            <div className='text-bg-warning'>
                                                {age} Jahre
                                            </div>
                                            :
                                            <div className='text-bg-danger'>
                                                {age} Jahre
                                            </div>
                                        )  
                                    )
                                }
                            </div>
                        </div>
                        <div className='row text-center'>
                            <div className='col-3'>
                                <span>Abwesend</span>
                            </div>
                            <div className='col-9 pe-0'>
                                {
                                    loading
                                    ?
                                    <h4>...loading...</h4>
                                    :
                                    (
                                        data.eventStatus === "LESS_THAN_THREE_MONTHS"
                                        ?
                                        <div className='text-bg-success'>
                                            &lt; 3 Monate
                                        </div>
                                        :
                                        (
                                            data.eventStatus === "THREE_MONTHS"
                                            ?
                                            <div className='text-bg-success bg-opacity-75'>
                                                = 3 Monate
                                            </div>
                                            :
                                            (
                                                data.eventStatus === "FOUR_MONTHS"
                                                ?
                                                <div className='text-bg-danger bg-opacity-10'>
                                                    = 4 Monate
                                                </div>
                                                :
                                                (
                                                    data.eventStatus === "FIVE_MONTHS"
                                                    ?
                                                    <div className='text-bg-danger bg-opacity-25'>
                                                        = 5 Monate
                                                    </div>
                                                    :
                                                    (
                                                        data.eventStatus === "SIX_MONTHS"
                                                        ?
                                                        <div className='text-bg-danger bg-opacity-50'>
                                                            = 6 Monate
                                                        </div>
                                                        :
                                                        <div className='text-bg-danger bg-opacity-75'>
                                                            &gt; 6 Monate
                                                        </div>
                                                    )
                                                )
                                            )
                                        )  
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className='col-4'>

                    </div>
                    <div className='col-3'>
                        <div className='row text-center'>
                            <span>vorgeschlagener Status:</span>
                        </div>
                        <div className='row'>
                            {
                                loading
                                ?
                                <h4>...loading...</h4>
                                :
                                (
                                    data.allowedInactive && data.suggestInactive
                                    ?
                                    <div className='col text-center'>
                                        <div className='row text-bg-danger'>
                                            <div className='col-10 align-self-center'>
                                                <h4>Inaktiv</h4>
                                            </div>
                                            {
                                                data.suggestInactive === participant.active
                                                ?
                                                <div className='col-2 align-self-center'>
                                                    <ArrowLeftRight size={24} color="black" title="Ändere Aktivitätsstatus" onClick={e => changeActiveStatus(e, false)}/>
                                                </div>
                                                :
                                                <></>
                                            }     
                                        </div>
                                    </div>
                                    :
                                    <div className='col text-center'>
                                        <div className='row text-bg-primary'>
                                            <div className='col-10 align-self-center'>
                                                <h4>Aktiv</h4>
                                            </div>
                                            {
                                                data.suggestInactive === participant.active
                                                ?
                                                <div className='col-2 align-self-center'>
                                                    <ArrowLeftRight size={24} color="black" title="Ändere Aktivitätsstatus" onClick={e => changeActiveStatus(e, true)}/>
                                                </div>
                                                :
                                                <></>
                                            }     
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    {
                        participant.nextConsultant
                        ?
                        <></>
                        :
                        <div className="col-1 text-center align-self-center">
                            <PersonWorkspace size={35} title="Erzeuge Teamer/Referentenaccount" onClick={e => turnToConsultant(e)}/>
                        </div>                    
                    }
                    
                </div>
            </div>
            */}
        </Card>
    )
}
export default SingleParticipantActivenessView;