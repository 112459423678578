import PTable from "./PTable";
import 'bootstrap/js/dist/dropdown'
import React, {useState, useEffect} from 'react';
import MultiSelectDropdown from "../../containers/custom/MultiSelectDropdown";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import { Plus, PeopleFill } from 'react-bootstrap-icons';
import NewParticipantModal from "./NewParticipantModal";
import {toast} from "react-toastify";
import { Breadcrumb, Nav, Navbar } from "react-bootstrap";
import ToastProvider from "../../containers/ToastProvider";

function Participants(props) {

    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = React.useState(false);
    const [connection, setConnection] = React.useState(true);
    const [noEntries, setNoEntries] = React.useState(false);
    const [modalShow, setModalShow] = React.useState(false);
    const [toastInfo, setToastInfo] = React.useState({show: false, message: "", theme: "success"});

    const participants = props.participants

    const dropDownData = [
        {id:0, value: "ID"},
        {id:1, value: "Vorname"},
        {id:2, value: "Nachname"},
        {id:3, value: "OG"},
        {id:4, value: "Geburtsdatum"},
        {id:5, value: "Alter"},
        {id:6, value: "Telefonnummer"},
        {id:7, value: "Email"},
        {id:8, value: "Threema-ID"},
        {id:9, value: "Notfallnummer"},
        {id:10, value: "DRSA"},
        {id:11, value: "TShirt"},
        {id:12, value: "Hose"},
        {id:13, value: "Jacke"},
        {id:14, value: "Bildrechte"},
    ]
    const dropDownFilterData= [
        {id:0, value: "Teilnehmer", enum: "PART"},
        {id:1, value: "Helfer", enum: "TEAMER"},
        {id:2, value: "Aktive", enum: "ACTIVE"},
        {id:3, value: "Inaktive", enum: "INACTIVE"},
    ]

    const [dropDownCheck, setDropDownCheck] = useState([false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true])
    const [dropDownFilterCheck, setDropDownFilterCheck] = useState([true,true,true,false])


    useEffect(() => {
        loadData();
    }, [])

    function changeCheckBoxValue(i) {
        const list = [...dropDownCheck];
        list[i] = !list[i]

        setDropDownCheck(list)
    }
    function changeCheckBoxFilterValue(i) {
        const list = [...dropDownFilterCheck];
        list[i] = !list[i]

        setDropDownFilterCheck(list)
    }

    async function loadData() {
        if(participants.length === 0 && !noEntries) {
            setLoading(true)
        }
        await axiosPrivate.get("/participants")
            .then((response) => {
                props.setParticipants(response.data)
                if(response.data.length === 0) {
                    setNoEntries(true)
                }else {
                    setNoEntries(false)
                }
                setConnection(true)
            })
            .catch(error => {
                if(error.code === "ERR_NETWORK") {
                    props.setParticipants([])
                    setConnection(false)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function addParticipant(formInput) {
        const content = {
            first: formInput.first,
            last: formInput.last,
            dateOfBirth: formInput.dateOfBirth,
            localGroup: formInput.localGroup,
            phoneNumber: formInput.phoneNumber,
            email: formInput.email,
            threemaID: formInput.threemaID,
            emergencyPhoneNumber: formInput.emergency_phoneNumber,
            drsa: formInput.drsa,
            imageRight: formInput.imageRight,
            note: formInput.note
        }

        var response
        var status

        var toastMessage
        var toastTheme

        await axiosPrivate.post("/participants/add", content)
            .then(res => response = res.data)
            .catch(function (error) {
                status = error.response?.status
            })
        if(status === 403) {
            toastMessage = "Missing permission"
            toastTheme = "warning"
        }else {
            if(response.allowed === 'false') { 
                toastMessage = response.message
                toastTheme = "danger"
			}else {
                toastMessage = "Teilnehmer wurde erfolgreich hinzugefügt!"
                toastTheme = "success"
			}
        }
        setModalShow(false)
        await loadData();
        setToastInfo({show: true, message: toastMessage, theme: toastTheme})
    }

    return(
        <div className="row">
            <main role="main" className="ml-sm-auto col-md-9 col-xl-12 pt-3">

                <Breadcrumb className="ms-3">
                    <Breadcrumb.Item href="/participants" className="text-body-secondary">
                        <PeopleFill/>
                    </Breadcrumb.Item>
                </Breadcrumb>

                <ToastProvider show={toastInfo.show} message={toastInfo.message} theme={toastInfo.theme} onClose={() => setToastInfo({show: false, message: "", theme: "success"})}/>

                {/*
                <nav className="breadcrumb bg-dark">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link  to="/participants">
                                <PeopleFill/>
                            </Link>
                        </li>
                    </ol>
                </nav>
                */}

                <NewParticipantModal show={modalShow} onHide={() => setModalShow(false)} onSubmit={input => addParticipant(input)}/>

                <div id="content">
                    <Navbar expand="lg" className="justify-content-between">
                        <Nav className="mr-auto">
                            <Nav.Item className="dropdown">
                                <MultiSelectDropdown text="Spalten ein-/ausschalten" initialData={dropDownCheck} data={dropDownData} onChange={(id) => changeCheckBoxValue(id)} className="btn btn-sm"/>
                            </Nav.Item>
                            <Nav.Item className="dropdown">
                                <MultiSelectDropdown text="Zeilen ein-/ausschalten" data={dropDownFilterData} initialData={dropDownFilterCheck} onChange={(id) => changeCheckBoxFilterValue(id)} className="btn btn-sm"/>
                            </Nav.Item>
                        </Nav>
                        <Nav className="mr-auto">
                            <Nav.Item>
                                <button type="button" className="btn btn-sm btn-success" onClick={() => setModalShow(true)}><Plus size={20}></Plus></button>
                            </Nav.Item>
                        </Nav>
                    </Navbar>
                    <div style={{"marginBottom" : "auto"}}>
                        <PTable connection={connection} loading={loading} participants={participants?.filter(
                            p => ((dropDownFilterCheck[0] && p.participantRole === "PARTICIPANT") || (dropDownFilterCheck[1] && p.participantRole === "TEAMER")))
                            .filter(
                                p => ((dropDownFilterCheck[2] && p.active) || (dropDownFilterCheck[3] && !p.active))
                            )} checkList={dropDownCheck} checkFilterList={dropDownFilterCheck}/>
                    </div>
                </div>

                {/*
                <div className="d-flex justify-content-between">
                    <div className="d-flex justify-content-start">
                        <MultiSelectDropdown text="Spalten ein-/ausschalten" initialData={dropDownCheck} data={dropDownData} onChange={(id) => changeCheckBoxValue(id)} className="pe-3"/>
                        <MultiSelectDropdown text="Zeilen ein-/ausschalten" data={dropDownFilterData} initialData={dropDownFilterCheck} onChange={(id) => changeCheckBoxFilterValue(id)} className="col"/>
                    </div>       
                    <button type="button" className="btn btn-success" onClick={() => setOpenImpuls(!openImpuls)}><Plus size={20}></Plus></button>
                </div>
                <PTable connection={connection} loading={loading} participants={participants?.filter(
                    p => ((dropDownFilterCheck[0] && p.participantRole === "PARTICIPANT") || (dropDownFilterCheck[1] && p.participantRole === "TEAMER")))
                    .filter(
                        p => ((dropDownFilterCheck[2] && p.active) || (dropDownFilterCheck[3] && !p.active))
                    )} checkList={dropDownCheck} checkFilterList={dropDownFilterCheck}/>
                */}
            </main>
        </div>
    )
}
export default Participants;