import React, {useEffect, useRef} from "react";
import { PlusSquareFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../jwt/useAxiosPrivate";
import { Typeahead } from "react-bootstrap-typeahead";
import SingleEventConsultantListEntryView from "./SingleEventConsultantListEntryView";
import { Col, Row } from "react-bootstrap";

function SingleEventConsultantListView({evid, event, reload}) {

    const [consultants, setConsultants] = React.useState([])
    const [selectedConsultant, setSelectedConsultant] = React.useState(null);

    const [loading, setLoading] = React.useState(true);
    const [connection, setConnection] = React.useState(true)
    const [unknownError, setUnknownError] = React.useState(false)

    const navigate = useNavigate()
    const axiosPrivate = useAxiosPrivate();

    const typeaheadConsultantRef = useRef(null);

    useEffect(() => {
        loadConsultants();
    },[])

    async function loadConsultants() {
        await axiosPrivate.get("/consultants/simpleOverview")
            .then((response) => {
                setConsultants(response.data)
            })
            .catch(error => {
                if(error.response?.status === 404 || error.response?.status === 400) {
                    setConnection(true)
                    setUnknownError(false)
                }else if(error.code === "ERR_NETWORK") {
                    setConnection(false)
                    setUnknownError(false)
                }else {
                    setUnknownError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }

    async function addConsultantToEvent(e) {
        e.preventDefault();

        if(event.consultants.map(c => c.cid).includes(selectedConsultant) || selectedConsultant===null || selectedConsultant===undefined) {
            typeaheadConsultantRef.current.clear();
            return
        }

        await axiosPrivate.post("/events/" + evid + "/addConsultant/" + selectedConsultant)

        typeaheadConsultantRef.current.clear();
        await reload();
    }


    async function removeConsultantFromEvent(cid) {
        await axiosPrivate.post("/events/" + evid + "/removeConsultant/" + cid)
        
        await reload();
    }

    return (
        <Row>
            {
                unknownError
                ?
                <h2 className='text-center text-danger'>...!UNBEKANNTER FEHLER!...</h2>
                :
                (
                    !connection
                    ?
                    <h2 className='text-center text-danger'>...NO CONNECTION TO BACKEND...</h2>
                    :
                    (
                        <Col>
                            <Row>
                                <span>Referenten:</span>
                            </Row>
                            <Row>
                                {
                                    loading || event.consultants === undefined
                                    ?
                                    <></>
                                    :
                                    <>
                                        <Col md="10">
                                            <Typeahead
                                                id="typeahead-consultants"
                                                labelKey={option => `${option.cid} - ${option.first} ${option.last}`}
                                                options={consultants.filter(
                                                    c => !event.consultants.map(cu => cu.cid).includes(c.cid)
                                                    )}
                                                onChange={(selected) => {setSelectedConsultant(selected[0]?.cid)}}
                                                ref={typeaheadConsultantRef}
                                                placeholder="Referent suchen"
                                            />
                                        </Col>
                                        <Col md="2" className="align-self-center">
                                            <PlusSquareFill size={30} color="green" onClick={addConsultantToEvent}/>
                                        </Col>
                                    </>
                                }
                                
                            </Row>
                            <Row className="justify-content-md-center">
                                <Col md="11" className="scrollbarHideY" style={{maxHeight: "45vh"}}>
                                    {
                                        !loading && event.consultants !== undefined
                                        ?
                                        (
                                            event.consultants.length > 0
                                            ?
                                            Array.from({length: event.consultants.length}, (_,i) => {
                                                return (
                                                    <SingleEventConsultantListEntryView consultant={event.consultants[i]} bottom={i === event.consultants.length-1} removeConsultantFromEvent={removeConsultantFromEvent}/>
                                                )
                                            })
                                            :
                                            <Row>
                                                <h3>-- keine Einträge --</h3>
                                            </Row>
                                        )   
                                        :
                                        <Row>
                                            <h3>... loading ...</h3>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    )
                )
            }
        </Row>
    )

}
export default SingleEventConsultantListView;