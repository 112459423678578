import { useParams } from 'react-router';
import useAxiosPrivate from '../../jwt/useAxiosPrivate';
import React, { useEffect } from 'react';
import SingleParticipantEventView from './SingleParticipantEventView';
import SingleParticipantActivenessView from './SingleParticipantActivenessView';
import SingleParticipantGenerallDetailView from './SingleParticipantGenerallDetailView';
import { PeopleFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import {Breadcrumb, Navbar} from 'react-bootstrap';

function SingleParticipant() {

    const {uid} = useParams()
    const axiosPrivate = useAxiosPrivate()

    const [loading, setLoading] = React.useState(true);
    const [data, setData] = React.useState({assignedEquipment: [], attendedDates: []})
    const [connection, setConnection] = React.useState(true)
    const [found, setFound] = React.useState(true)
    const [unknownError, setUnknownError] = React.useState(false)

    useEffect(() => {
        loadData();
    }, [])

    async function loadData() {
        setLoading(true)
        await axiosPrivate.get("/participants/" + uid)
            .then((response) => {

                var events = response.data.attendedDates
                if(response.data.nextConsultant != null) {
                    events = events.concat(response.data.nextConsultant.events)
                }
                response.data.events = events.sort((a,b) => new Date(b.date)-new Date(a.date))

                setData(response.data)
                setConnection(true)
                setFound(true)
                setUnknownError(false)
            })
            .catch(error => {
                if(error.response?.status === 404 || error.response?.status === 400) {
                    setFound(false)
                    setConnection(true)
                    setUnknownError(false)
                }else if(error.code === "ERR_NETWORK") {
                    setConnection(false)
                    setUnknownError(false)
                }else {
                    setUnknownError(true)
                }
            })
            .finally(() => {
                setLoading(false)
            }) 
    }

    async function removeEquip(e,eid) {
        e.preventDefault();

        if(!window.confirm("Willst du das Ausrüstungsstück wirklich entfernen?")) {
            return;
        }

        await axiosPrivate.post("/equipment/removeEquip/"+uid+"/"+eid)
        .catch(error => {
            if(error.response?.status === 404 || error.response?.status === 400) {
                setFound(false)
                setConnection(true)
                setUnknownError(false)
            }else if(error.code === "ERR_NETWORK") {
                setConnection(false)
                setUnknownError(false)
            }else {
                setUnknownError(true)
            }
        })
        .finally(() => {
            loadData();
        })
    }

    async function loadHead() {
        await loadData();
    }

    async function modalAddEquipment(eid) {
        await axiosPrivate.post("/equipment/addEquip/" + uid + "/" + eid)
        .finally(() => {
            loadData();
        })
    }

    return(
        <div className='row'>
            <main role="main" className="ml-sm-auto col-md-9 col-xl-12 pt-3">

                <Breadcrumb className='ms-3'>
                    <Breadcrumb.Item href="/participants">
                        <PeopleFill/>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href={"/participants/"+uid}>
                        {loading ? uid : data.first + " " + data.last}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <div id="content">
                    <div className='row'>
                        <div className='col-4 d-none d-md-block sidebar'>
                            <SingleParticipantEventView events={data.events} loading={loading}/>
                        </div>
                        <div className="col-8 px-5 text-light">
                            <SingleParticipantGenerallDetailView loading={loading} connection={connection} found={found} unknownError={unknownError} participant={data} removeEquip={removeEquip} modalAddEquipment={modalAddEquipment}/>
                            <SingleParticipantActivenessView uid={uid} participant={data} loading={loading} loadHead={loadHead}/>      
                        </div> 
                    </div>
                </div>

                {/*
                <div className='row mt-3 me-5'>
                    <div className='col-4'>
                        <SingleParticipantEventView events={data.events} loading={loading}/>
                    </div>
                    <div className="col-8 px-0">
                        <SingleParticipantGenerallDetailView loading={loading} connection={connection} found={found} unknownError={unknownError} participant={data} removeEquip={removeEquip} modalAddEquipment={modalAddEquipment}/>
                        <SingleParticipantActivenessView uid={uid} participant={data} loading={loading} loadHead={loadHead}/>      
                    </div> 
                </div>
                */}
            </main>
        </div>
    )
}

export default SingleParticipant;