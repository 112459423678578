import axios from 'axios';
const BASE_URL = "https://api.hugohirling.com:8443/jetpms-1.0.4"
//const BASE_URL = "http://localhost:8080"

export default axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json'}
})

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {'Content-Type': 'application/json'}
})