import './App.css';
import Layout from './containers/Layout';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home from "./pages/Home";
import Participants from "./pages/ParticipantList/Participants";
import Equipment from "./pages/EquipmentList/Equipment";
import Events from "./pages/EventList/Events";
import NoPage from "./pages/NoPage";
import { AuthProvider } from './jwt/auth';
import { Login } from './pages/Login';
import PrivateRoutes from './jwt/PrivateRoutes';
import SingleParticipant from './pages/SingleParticipant/SingleParticipantPage';
import SingleEvent from './pages/SingleEvent/SingleEventPage';
import React from 'react';
import Specs from "./pages/Specs";

function App() {

  const [participants, setParticipants] = React.useState([])
  const [equipment, setEquipment] = React.useState([])
  const [events, setEvents] = React.useState([])
  const [specs, setSpecs] = React.useState({})
  

  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<PrivateRoutes />}>
              <Route index element={<Home />} />
              <Route path="participants" element={<Participants participants={participants} setParticipants={(p) => setParticipants(p)}/>} />
              <Route path="participants/:uid" element={<SingleParticipant />} />
              <Route path="equipment" element={<Equipment equipment={equipment} setEquipment={(e) => setEquipment(e)}/>} />
              <Route path="events" element={<Events events={events} setEvents={(ev) => setEvents(ev)}/>} />
              <Route path="events/:evid" element={<SingleEvent />} />
            </Route>
            <Route path="login" element={<Login />} />
            <Route path="specs" element={<Specs specs={specs} setSpecs={(s) => setSpecs(s)}/>} />
            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
