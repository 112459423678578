import Header from "./Header";
import { Outlet } from "react-router-dom";
import ToastProvider from "./ToastProvider";

function Layout() {
    return(
        <>
            
            <Header />
            <div className="container-fluid">
                <Outlet />
            </div>     
        </>
    );
}

export default Layout;