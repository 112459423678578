import { useState, createContext, useContext } from "react";

const AuthContext = createContext(null)

export const AuthProvider = ({children}) => {
    const [user, setUser] = useState(localStorage.getItem('user') || null)
    const [token, setToken] = useState(localStorage.getItem('token') || null)
    const [role, setRole] = useState(localStorage.getItem('role') || null)
    const [staySignedIn, setStaySignedIn] = useState(false)

    const login = (passedUser, passedToken, passedRole, stayLoggedIn) => {
        setToken(passedToken)
        setRole(passedRole)
        setUser(passedUser)
        setStaySignedIn(stayLoggedIn)
        if(stayLoggedIn) {
            localStorage.setItem('user', passedUser)
            localStorage.setItem('token', passedToken)
            localStorage.setItem('role', passedRole)
        }
    }

    const logout = () => {
        setUser(null)
        setToken(null)
        setRole(null)
        localStorage.removeItem('user')
        localStorage.removeItem('token', null)
        localStorage.removeItem('role', null)
    }

    return <AuthContext.Provider value={{user, token, role, login, logout}}>
        {children}
    </AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}